import { useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { ToolContext } from '../../../core/context/ToolContext';
import { rqx } from '../../../core/request/API';
import { env } from '../../../core/Env';
import { Welcome } from './Welcome';
import { WTD } from './WTD';
import { WTK } from './WTK';
import { Reasons } from './Reasons';
import { ChangeWTK } from './ChangeWTK';
import { Index as Tour } from './tour/Index'
export const Index = () => {
    const history = useHistory()
    const __LOCATION = useLocation().pathname
    const { tool_state, home_state } = useContext(ToolContext)
    const __SESSION = tool_state.__SESSION.data
    const pslztn = home_state.pslztn
    const userPslztn = JSON.parse(localStorage.getItem('sip_biz_session_'+__SESSION.ainfo.aid))
    const fetchPersonalization = async() => {
        let d = new Date()
        if(userPslztn === null){
            let fetchActivities = await rqx.get(`<<tool>>/${env()}/examActivity/get`, {aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), licensure_id: __SESSION.ulicensure[0].id})
            if(__LOCATION === '/biz'){
                if(__SESSION.pinfo.length === 0 && fetchActivities === 0){
                    pslztn.set('wtd');
                }
            }
        }else{
            let filterD = userPslztn.filter(f => f.date_added === `${d.getMonth()+1}${d.getUTCDate()}${d.getUTCFullYear()}`)
            if(filterD.length === 0){
                pslztn.set('wtd');
            }
        }
    }
    useEffect(() => {
        fetchPersonalization()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <>
        {
            pslztn.data === 'wtd' && <WTD __SESSION={__SESSION} pslztn={pslztn}/>
        }
        {
            pslztn.data === 'welcome' && <Welcome/>
        }
        {
            pslztn.data === 'wtk' && <WTK __SESSION={__SESSION} pslztn={pslztn}/>
        }
        {
            pslztn.data === 'reasons' && <Reasons __SESSION={__SESSION} pslztn={pslztn}/>
        }
        {
            pslztn.data === 'tour' && <Tour />
        }
        {
            pslztn.data === 'change-wtk'  && <ChangeWTK __SESSION={__SESSION} pslztn={pslztn}/>
        }
        </>
    )
}