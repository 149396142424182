import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { SnakeLoader } from '../core/loader/SnakeLoader';
import { SIPLogo } from '../core/global/Icons';
import ErrorBot from '../assets/images/core/error_bot.jpg'
import Pofsis from '../assets/images/logos/pofsis.png'
import { rqx } from '../core/request/API';
import { env } from '../core/Env';
export const SSORqx = () => {
    return <Router><Validate /></Router>
}

const Validate = () => {
    const q = new URLSearchParams(useLocation().search);
    const [err, setErr] = useState(false)
    
    useEffect(() => {
        const __init = async () => {
            if (q.get('ainfo') !== null) {
                const ainfo = JSON.parse(q.get('ainfo'))
                const acc = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/account/read`, {aid: ainfo.aid})
                if (typeof acc === 'object') {
                    setErr(false)
                    let d = new Date()
                    d.setDate(d.getDate() + 30);
                    
                    const strg = {
                        expiration: `${d.getMonth()+1}${d.getUTCDate()}${d.getUTCFullYear()}`,
                        ainfo: ainfo
                    }
                    localStorage.setItem('sip_session', JSON.stringify(strg))
                    window.location.href = '/psl'
                } else {
                    setErr(true)
                }
            }
        }
        setTimeout(() => {
            __init()
        }, 2400);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return !err ? (
        <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box mt={0.5} zIndex={2}>
                <SIPLogo size={60}/>
            </Box>
            <Box fontSize="20px" mt="20px" mb="20px">
                <Box component="span" fontWeight={600}>SUREiPASS</Box> Personal
            </Box>
            <SnakeLoader size="8px" bg="#0070EF" distance="0.1rem" />
            <Box mt="20px" fontSize="12px" color="#52616B">Please wait, It take only 1 minute...</Box>
            <Box sx={{position: 'fixed', bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', mb: '26px'}}>
                <img src={Pofsis} alt={Pofsis} />
                <Box ml="16px" fontSize="16px" fontWeight={600}>POFSIS</Box>
            </Box>
        </Box>
    ) : (
        <Box height="100%" width="100%" display="flex" justifyContent="center" bgcolor="#ffffff">
            <Box height="100%" width="400px" display="flex" justifyContent="center" alignItems="center">
                <Grid container>
                    <Grid item xs={12}>
                        <img src={ErrorBot} alt={ErrorBot} width="100%" />
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            <Box color="primary.main" fontSize={14} >POF<strong>SUREiPASS</strong></Box>
                            <Box fontSize={24}><b>SHEEESSHH!</b></Box> 
                            {/* <Box fontSize={18} mb={3}>Looks like an expolosion...</Box> */}
                            <Box fontSize={18} mb={3}>Well, this is unexpected. . .</Box>
                            <Box textAlign="justify" fontSize={14} mb={1}>
                                We were unable to authenticate you because your tool did not provide the proper authorization. Please contact your tool's customer support for assistance with this issue.
                            </Box>
                            <Box textAlign="justify" fontSize={14} color="gray">
                                Error code: AUTHORIZATION_ERROR. AID is invalid.
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}