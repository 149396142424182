import { lazy } from 'react';
import { Box, Container } from '@mui/material';
import { Route, Switch, useLocation } from 'react-router-dom';
import { VM } from '../VM';
let view = VM();
const Index = lazy(() => import(`../../__ubr/components${view}/Index`))
export const WebRoutev1 = () => {
    const location = useLocation().pathname;
    return (
        <Switch>
            <Route exact path="/ubr" render={props=>(Render(Index, props, location))} />
            <Route>
                <Container>
                    <Box display="flex">
                        Error 404
                    </Box>
                </Container>
            </Route>
        </Switch>
    )
}
const Render = (Component, props, location) => {
    return (
        <Box width="100%" height="100%">
            {
                view === '' ? (
                    <Box display="flex" width="100%" height="100%">
                        <Component {...props}/>
                    </Box>
                ) : (
                    <Box width="100%" height="100%">
                        <Box height="100%" width="100%"><Component {...props} /></Box>
                    </Box>
                )
            }
        </Box>
    )
}