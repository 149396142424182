import { Box, Link, Skeleton } from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PenEdit } from '../../../../../core/global/Icons';
import Unknown from '../../../../../assets/images/home/unknown-reason.png'
export const Reasons = ({myReasons}) => {
    const history = useHistory()
    const [active, setActive] = useState(0)
    return (
        <Box sx={{borderTopLeftRadius: '24px', borderTopRightRadius: '24px'}} p="24px" bgcolor="#fff">
            {
                myReasons.isLoading ? (
                    <>
                    <Box display="flex" justifyContent="space-between" mb="24px">
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '60%' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '5%' }} />
                    </Box>
                    <Skeleton variant="rounded" height={280} sx={{borderRadius: '24px', mb: '24px'}} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: '50%', mb: '20px' }} />
                    <Skeleton variant="rounded" height={200} sx={{borderRadius: '8px'}} />
                    </>
                ) : (
                    <>
                    <Box display="flex" justifyContent="space-between" mb="20px">
                        <Box fontSize="14px" fontWeight={600}>Knowing your deepest "why" is an important first step in figuring out how to pass your exam.</Box>
                        <Link href="#" onClick={()=>history.push('?account=reasons&action=update')}>
                            <PenEdit />
                        </Link>
                    </Box>
                    <Box sx={{backgroundImage: `url(${myReasons.image === null ? Unknown : myReasons.image[0].link})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', height: '243px', backgroundColor: '#F6F9FF', borderRadius: '6px', display: 'flex', justifyContent: 'center', alignItems: 'center', mb: '24px'}}></Box>
                    <Box mb="16px">My Top 3 Reason</Box>
                    <Box bgcolor="#F2F0D5" borderRadius="12px" p="12px">
                        <Box bgcolor="#FFFDE7" borderRadius="8px" p="12px" mb="12px">
                            {
                                active === 0 ? (
                                    <>
                                        <Box fontSize={13} fontWeight={600} color="#AAA573" mb="10px">1st Reason</Box>
                                        <Box>{myReasons.reasons === null ? 'I want to be ---' : myReasons.reasons.first_reason}</Box>
                                    </>
                                )  : (
                                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{cursor: 'pointer'}} onClick={()=>setActive(0)}>
                                        <Box fontSize={13} fontWeight={600} color="#AAA573">1st Reason</Box>
                                        <Box fontSize={16} fontWeight={600} color="#AAA573">...</Box>
                                    </Box>
                                )
                            }
                        </Box>
                        <Box bgcolor="#FFFDE7" borderRadius="8px" p="12px" mb="12px">
                            {
                                active === 1 ? (
                                    <>
                                    <Box fontSize={13} fontWeight={600} color="#AAA573" mb="10px">2nd Reason</Box>
                                    <Box>{myReasons.reasons === null ? 'I want to be ---' : myReasons.reasons.second_reason}</Box>
                                    </>
                                )  : (
                                    <Box display="flex" justifyContent="space-between" alignItems="center"  sx={{cursor: 'pointer'}} onClick={()=>setActive(1)}>
                                        <Box fontSize={13} fontWeight={600} color="#AAA573">2nd Reason</Box>
                                        <Box fontSize={16} fontWeight={600} color="#AAA573">...</Box>
                                    </Box>
                                )
                            }
                        </Box>
                        <Box bgcolor="#FFFDE7" borderRadius="8px" p="12px">
                            {
                                active === 2 ? (
                                    <>
                                    <Box fontSize={13} fontWeight={600} color="#AAA573" mb="10px">3rd Reason</Box>
                                    <Box>{myReasons.reasons === null ? 'I want to be ---' : myReasons.reasons.third_reason}</Box>
                                    </>
                                )  : (
                                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{cursor: 'pointer'}} onClick={()=>setActive(2)}>
                                        <Box fontSize={13} fontWeight={600} color="#AAA573">3rd Reason</Box>
                                        <Box fontSize={16} fontWeight={600} color="#AAA573">...</Box>
                                    </Box>
                                )
                            }
                        </Box>
                    </Box>
                    </>
                )
            }
        </Box>
    )
}