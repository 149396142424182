import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar } from '@mui/material'
import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import { CircleCheck } from '../../../core/global/Icons';
import { Close } from '../../../core/global/Icons';
import { ToolContext } from '../../../core/context/ToolContext'
import { SnakeLoader } from '../../../core/loader/SnakeLoader'
import { rqx } from '../../../core/request/API';
import { env } from '../../../core/Env';
import moment from 'moment';
export const ChangeWTK = ({__SESSION, pslztn}) => {
    const history = useHistory()
    const { home_state } = useContext(ToolContext)
    const myPlan = home_state.myPlan
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [days, setDays] = useState(0)
    const [value, setValue] = useState(dayjs(moment(new Date()).format('YYYY-MM-DD')))
    const handleChange = (newValue) => {
        setValue(newValue)
        let duration = moment(moment(newValue.$d.toISOString()).format('YYYY-MM-DD')).diff(new Date(),'days');
        setDays(duration)
    }
    const handleClose = () => {
        setOpen(false);
    }
    const handleSubmit = async() => {
        setLoading(true);
        let remaining = Math.round(days / 5);
        let day = moment().add(remaining, 'days');
        let targetTQ = [1800, 3600, 5400, 7200, 9000];
        let totalAQ = myPlan.data.plan.aq;
        let taken = totalAQ;
        let level = 0;
        let data = [];
        let pl =[];
        for (let i = 0; i < 5; i++) {
            pl.push({level: i+1, target_question: targetTQ[i], accomplish_date: moment(day).format('YYYY-MM-DD'), status: 0, created_at: moment().format('YYYY-MM-DD hh:mm:ss')});
            data.push({
                'level': i+1,
                'target': targetTQ[i], 
                'taken': level === i ? totalAQ >= targetTQ[i] ? targetTQ[i] : taken : 0,
                'percentage': level === i ? totalAQ >= targetTQ[i] ? 100 : Math.round(taken / targetTQ[i] * 100) : 0, 
                'date_accomplish': moment(day).format('YYYY-MM-DD'), 
                'status': myPlan.data.progress_level[i].status
            })
            day = moment(day).add(remaining, 'days');
            if(totalAQ >= targetTQ[i]){
                taken = totalAQ - targetTQ[i];
                level = level + 1;
            }
        }
        let params = {aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), pl: pl, 'month': moment(value.$d.toISOString()).format('M'), 'year': moment(value.$d.toISOString()).format('YYYY'), 'created_at': moment().format('YYYY-MM-DD hh:mm:ss')}
        let update = await rqx.post(`<<tool>>/${env()}/userPlan/update`, params)
        if(update.msg === 'success'){
            setOpen(true);
            myPlan.set({...myPlan.data, plan: {id: update.planId, target: moment(value.$d.toISOString()).format('YYYY-MM-DD'), days: days, aq: myPlan.data.plan.aq}, progress_level: data})
            setTimeout(() => {
                pslztn.set(null)
                setOpen(false);
            }, 2000);
        }
    }
    useEffect(() => {
        if(myPlan.data.plan !== null){
            let duration = moment(moment(myPlan.data.plan.target).format('YYYY-MM-DD')).diff(new Date(),'days');
            setDays(duration)
            setValue(dayjs(moment(myPlan.data.plan.target).format('YYYY-MM-DD')))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Box>
            <Dialog open={true} PaperProps={{ sx: { borderRadius: "12px", width:'425px'}}}>
                <DialogTitle sx={{fontSize:'20px', fontWeight:'600', lineHeight: '30px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Box width="80%">When do you plan to take the CPA Board exam?</Box>
                    <IconButton sx={{border: '1px solid #E5EBF6', borderRadius: '6px', padding: '3px'}} onClick={()=>history.push('/psl')}><Close /></IconButton>
                </DialogTitle>
                <DialogContent sx={{p: '0px'}}>
                    <Box pl="24px" pr="24px">
                        <Box sx={{boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.06)'}} borderRadius="6px" p="16px" mb="14px">
                            <Box color="#313131" fontSize="16px" mb="16px" fontWeight={600}>{moment(value.$d.toISOString()).format('MMM DD, YYYY')}</Box>
                            <Box color="#0070EF" fontSize="14px">{days} days remaining</Box>
                        </Box>
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar value={value} minDate={dayjs(moment(new Date()).format('YYYY-MM-DD'))} onChange={(e)=>handleChange(e)}/>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions sx={{mt: '15px', pb: '20px', pr: '20px'}}>
                    {
                        loading ? (
                            <Box bgcolor="#11783c" borderRadius="6px" p="6px 10px">
                                <Box height="25px" display="flex" alignItems="center" justifyContent="center"><SnakeLoader size="10px" bg="#fff" distance="0.3rem" /></Box>
                            </Box>
                        ) : (
                            <Button variant="outlined" color="primary" sx={{padding: '6px 24px'}} onClick={handleSubmit}>Set</Button>
                        )
                    }
                    
                </DialogActions>
            </Dialog>
            <Snackbar open={open} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} autoHideDuration={6000} onClose={handleClose}>
                <Alert variant="filled"  severity="success" icon={<CircleCheck fontSize="inherit" fill="#008F5D"/>} sx={{ width: '100%', color: '#008F5D', bgcolor: '#E8F5E9', border: '1px solid #008F5D'}}>
                    Date successfully set.
                </Alert>
            </Snackbar>
        </Box>
    )
}