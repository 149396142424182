import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { rqx } from '../../../../core/request/API';
import { env } from '../../../../core/Env';
import { convertTake } from '../global/Functions';
export const List = ({examStatus, results, setIsDialog, overview, setOverview, urlParam}) => {
    const history = useHistory()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event, index) => {
        setOverview({...overview, index: index, summary: results[index]})
        setAnchorEl(event.currentTarget);
    };
    const handleAction = () => {
        setAnchorEl(null);
        if(examStatus === 2) {
            if(results.length === overview.summary.take){
                setIsDialog({open: true, name: 'retake'})
            }else{
                handleShowAnswers();
            }
        }else{
            handleShowAnswers();
        }
    }
    const handleShowAnswers = () => {
        history.push(`/biz/take-exam?page=show-answers&sid=${urlParam.get('sid')}&stid=${urlParam.get('stid')}&sname=${urlParam.get('sname')}&tid=${urlParam.get('tid')}&tname=${urlParam.get('tname')}&setId=${urlParam.get('setId')}&setName=${urlParam.get('setName')}&setCategory=${urlParam.get('setCategory')}&type=${urlParam.get('type')}&take=${urlParam.get('take')}&etid=${urlParam.get('etid')}&esid=${urlParam.get('esid')}&eaid=${overview.summary.id}&tq=${overview.summary.tq}&from=exam`)
    }
    const handleSeeMore = async() => {
        setAnchorEl(null);
        setOverview({...overview, open: true, loading: true})
        let fetch = await rqx.get(`<<tool>>/${env()}/examActivity/SEDResults`, {uaid: results[overview.index].id})
        if(fetch.length > 0){
            setOverview({...overview, open: true, loading: false, sed: fetch})
        }
    }
    return (
        <>
        {
            results.map((result, key, arr) => (
                <Box key={key} display="flex" justifyContent="space-between" textAlign="center" alignItems="center" bgcolor="#fff" p="10px" borderRadius="10px" fontSize="12px" mt="10px" mb="5px" border="1px solid #E5EBF6">
                    <Box width="10%">{result.take + convertTake(result.take)} Take</Box>
                    <Box width="10%">
                        <IconButton aria-label="Setting" id="demo-positioned-button" aria-controls={open ? 'demo-positioned-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={(event)=>handleClick(event, key)}><MoreVertIcon /></IconButton>
                    </Box>
                    <Box width="10%">{moment(result.created_at).format('L')}</Box>
                    <Box width="10%">{result.tq}</Box>
                    <Box width="10%">{result.score}</Box>
                    <Box width="10%">{Math.round(result.score/result.tq*100)}%</Box>
                    <Box width="10%">{
                        result.take < 3 ? '0%' : <>
                        {
                            arr.filter((f,i) => { return (i + 2) === key + 1}).length > 0 
                            ? 
                                <>
                                {parseFloat(((results[results.length - 1].tq - arr.filter((f,index) => {return (index + 1) === key+1})[0].tq ) / results[results.length - 1].tq * 100)).toFixed(0) }
                                </>
                            : 
                                examStatus === 1 ? '100' : parseFloat( ((results[results.length - 1].tq - result.tq) / results[results.length - 1].tq) * 100).toFixed(0)
                        }%
                        </> 
                    }</Box>
                    <Box width="10%"><Box component="span" p="4px 8px" borderRadius="100px" color={result.status === 1 ? '#008F5D' : '#FA3E3E'} bgcolor={result.status === 1 ? '#f7fcfa' : '#FFEBEE'}>{result.status === 1 ? 'Passed' : 'Failed'}</Box></Box>
                    <Box width="10%">{result.alloted_time}</Box>
                    <Box width="10%">{result.time_spent}</Box>
                    
                </Box>
            ))
        }
        <Menu id="demo-positioned-menu" aria-labelledby="demo-positioned-button" open={open} anchorEl={anchorEl} anchorOrigin={{vertical: 'bottom',horizontal: 'right',}} transformOrigin={{vertical: 'top',horizontal: 'right',}} onClose={()=> setAnchorEl(null)}>
            <MenuItem onClick={handleSeeMore}>See More</MenuItem>
            {
                (results.length === overview.summary.take && examStatus === 2) && <MenuItem onClick={handleAction}>Retake</MenuItem>
            }
            <MenuItem onClick={handleAction}>Review Answer</MenuItem>
        </Menu>
        </>
    )
}