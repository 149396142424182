import { Box, Grid, Typography } from '@mui/material'
import { avatarstyle } from '../../../core/styling/global'
import PSLLogo from '../../../assets/images/logos/logo_v1.png';
export const List = ({d, v, pages}) => {
    const display = JSON.parse(d.profile).profile === '' || JSON.parse(d.profile).profile === undefined || JSON.parse(d.profile).profile === null || JSON.parse(d.profile).profile === 'unknown' ? PSLLogo : JSON.parse(d.profile).profile
    return (
        <Box width="100%" height="100" bgcolor="#FFFFFF" padding="24px" boxShadow="0 0 16px rgba(198, 206, 222, 32%)" borderRadius="8px" my={2} display="flex" alignItems="center" >
            <Grid container direction="row"  justifyContent="center" alignItems="center">
                <Grid item lg={2} sm={2} >
                    <Box width="100%" display="flex" alignItems="center">
                        <Typography fontSize={14} fontWeight="bold" pr={10}>{v + pages.list_num}</Typography>
                        <Box display="flex" justifyContent="center" >
                            <Box className={avatarstyle(display, 35, 35,)} mr={1} sx={{borderRadius: '12px !important',border: v === 0 ? '2px solid #2EAE84' :   v === 1 ? '2px solid #5B8DFD' : v === 2 ? '2px solid #FF6B6B' : '2px solid #DEE2EA'}}/>
                        </Box>
                    </Box>
                </Grid>
                <Grid item lg={2} sm={2} >
                    <Typography width="100%" display="flex" justifyContent="center" fontSize="14px" color="#333333">{d.first_name}</Typography>
                </Grid>
                <Grid item lg={2} sm={2} >
                    <Typography width="100%" display="flex" justifyContent="center" fontSize="14px" color="#333333">{d.last_name}</Typography>
                </Grid>
                <Grid item lg={2} sm={2} >
                    <Box width="100%" display="flex" justifyContent="center">
                        <Typography fontSize="14px" color="#333333" textOverflow="ellipsis" maxWidth="70%" noWrap></Typography>
                    </Box>
                </Grid>
                <Grid item lg={2} sm={2} >
                    <Box width="100%" display="flex" justifyContent="center">
                        <Typography fontSize="14px" color="#333333" textOverflow="ellipsis" maxWidth="70%" noWrap>----</Typography>
                    </Box>
                </Grid>
                <Grid item lg={2} sm={2} >
                    <Typography width="100%" display="flex" justifyContent="center" fontSize="14px" color="#333333" fontWeight="bold">{d.points}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}
