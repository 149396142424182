import { Avatar, Box, IconButton, Skeleton } from "@mui/material"
import { useContext, useState, useEffect } from 'react';
import { PenEdit } from '../../../core/global/Icons';
import PSLLogo from '../../../assets/images/core/avatar2.png'
import { ToolContext } from '../../../core/context/ToolContext';
import { env } from '../../../core/Env';
import { rqx } from '../../../core/request/API';
export const Profile = () => {
    const { tool_state } = useContext(ToolContext)
    const { __SESSION } = tool_state
    const display = __SESSION.data.pinfo.length > 0 ? (__SESSION.data.pinfo[0].profile !== null ? JSON.parse(__SESSION.data.pinfo[0].profile).profile !== null ? JSON.parse(__SESSION.data.pinfo[0].profile).profile : PSLLogo : PSLLogo) : PSLLogo
    const [readProfession, setreadProfession] = useState([])
    const [loading, setLoading] = useState(true)
    const fetchProfession = async(professionLists) => {
        let data = await rqx.get(`<<psl>>/${env()==='local'? 'dev' : env()}/profile/info/profession/read`, { user_id: parseInt(window.atob(__SESSION.data.ainfo.aid).split('_')[1])})
        if(data.length > 0){
        }else{
            setreadProfession(data)
        }  
        setLoading(false)
    }
    useEffect(() => {
        fetchProfession()
        // eslint-disable-next-line
    }, [])
    return (
        <Box height="100%" width="100%" bgcolor="#fff">
            <Box height="100%" width="80%" mt="54px" p="41px 56px">
                <Box fontWeight={700} fontSize="24px" lineHeight="38px" mb="40px">My Profile</Box>
                <Box display="flex" alignItems="center" mb="20px">
                    <Avatar alt="Remy Sharp" sx={{width: 80, height: 80}} src={`${display}`} />
                    <Box fontSize="14px" fontWeight={600} ml="10px">{__SESSION.data.pinfo[0].first_name+' '+__SESSION.data.pinfo[0].last_name}</Box>
                </Box>
                <Box border="1px solid #E5EBF6" borderRadius="6px" p="16px" mb="20px">
                    {
                        loading ? (
                            <>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mb="20px">
                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '160px' }} />
                                <Skeleton variant="rounded" width={28} height={28} />
                            </Box>
                            <Box display="flex">
                                {
                                    [0,1].map(i => (
                                        <Box key={i} width="50%">
                                            {
                                                [0,1].map(k => (
                                                    <Box key={k} display="flex" mb="10px">
                                                        <Box width="50%"><Skeleton variant="text" sx={{ fontSize: '14px', width: '100px' }} /></Box>
                                                        <Box width="50%"><Skeleton variant="text" sx={{ fontSize: '14px', width: '100px' }} /></Box>
                                                    </Box>
                                                ))
                                            }
                                        </Box>
                                    ))
                                }
                            </Box>
                            </>
                        ) : (
                            <>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mb="20px">
                                <Box color="#313131" fontSize="14px" fontWeight={600}>Personal Information</Box>
                                <IconButton><PenEdit /></IconButton>
                            </Box>
                            <Box display="flex">
                                <Box width="50%">
                                    <Box display="flex" mb="10px">
                                        <Box color="#89A594" fontSize="14px" fontWeight={400} width="50%">Last Name</Box>
                                        <Box color="#313131" fontSize="14px" fontWeight={400} width="50%">{__SESSION.data.pinfo[0].last_name}</Box>
                                    </Box>
                                    <Box display="flex">
                                        <Box color="#89A594" fontSize="14px" fontWeight={400} width="50%">Middle Name</Box>
                                        <Box color="#313131" fontSize="14px" fontWeight={400} width="50%">{__SESSION.data.pinfo[0].middle_name === 'None' ? '---' : __SESSION.data.pinfo[0].middle_name}</Box>
                                    </Box>
                                </Box>
                                <Box width="50%">
                                    <Box display="flex" mb="10px">
                                        <Box color="#89A594" fontSize="14px" fontWeight={400} width="50%">First Name</Box>
                                        <Box color="#313131" fontSize="14px" fontWeight={400} width="50%">{__SESSION.data.pinfo[0].first_name}</Box>
                                    </Box>
                                    <Box display="flex">
                                        <Box color="#89A594" fontSize="14px" fontWeight={400} width="50%">Suffix</Box>
                                        <Box color="#313131" fontSize="14px" fontWeight={400} width="50%">{__SESSION.data.pinfo[0].suffix === 'None' ? '---' : __SESSION.data.pinfo[0].suffix}</Box>
                                    </Box>
                                </Box>
                            </Box>
                            </>
                        )
                    }
                </Box>
                <Box border="1px solid #E5EBF6" borderRadius="6px" p="16px">
                    {
                        loading ? (
                            <>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mb="20px">
                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '160px' }} />
                                <Skeleton variant="rounded" width={28} height={28} />
                            </Box>
                            <Box display="flex">
                                <Box display="flex" mb="10px" width="50%" justifyContent="space-between">
                                    <Box width="50%"><Skeleton variant="text" sx={{ fontSize: '14px', width: '100px' }} /></Box>
                                    <Box width="50%"><Skeleton variant="text" sx={{ fontSize: '14px', width: '100px' }} /></Box>
                                </Box>
                                <Box display="flex" mb="10px" width="50%" justifyContent="space-between">
                                    <Box width="50%"><Skeleton variant="text" sx={{ fontSize: '14px', width: '100px' }} /></Box>
                                    <Box width="50%"><Skeleton variant="text" sx={{ fontSize: '14px', width: '100px' }} /></Box>
                                </Box>
                            </Box>
                            </>
                        ) : (
                            <>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mb="20px">
                                <Box color="#313131" fontSize="14px" fontWeight={600}>Professional Overview</Box>
                                <IconButton><PenEdit /></IconButton>
                            </Box>
                            <Box display="flex">
                                <Box display="flex" mb="10px"  width="50%">
                                    <Box color="#89A594" fontSize="14px" fontWeight={400} width="50%">Classification</Box>
                                    <Box color="#313131" fontSize="14px" fontWeight={400} width="50%">{readProfession.length > 0 ? (readProfession[0].classification === 1 ? 'Student' : 'Professional') : '---'}</Box>
                                </Box>
                                <Box display="flex"  width="50%">
                                    <Box color="#89A594" fontSize="14px" fontWeight={400} width="50%">Type of Profession</Box>
                                    <Box color="#313131" fontSize="14px" fontWeight={400} width="50%">{readProfession.length > 0 ? (readProfession[0].classification === 1 ? '---' : readProfession[0].profession_name) : '---'}</Box>
                                </Box>
                            </Box>
                            </>
                        )
                    }
                </Box>
            </Box>
        </Box>
    )
}