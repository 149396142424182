import { Box, Button, Breadcrumbs, Divider, Grid, Link, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Play } from '../../../../core/global/Icons';
import { SED } from './modals/SED';
import { HTPTE } from './modals/HTPTE';
import { rqx } from '../../../../core/request/API';
import { env } from '../../../../core/Env';
export const Index = ({urlParam, __SESSION}) => {
    const history = useHistory()
    const [loading, setLoading] = useState(true);
    const [sets, setSets] = useState([]);
    const [exam, setExam] = useState({isDialog: false, dialog: null, id: '', name: '', category: ''})
    const handleTakeExam = (id, name, category) => {
        setExam({isDialog: true, dialog: 'SED', id: id, name: name, category: category});
    }
    const fetchSets = async() => {
        let fetch = await rqx.get(`<<tool>>/${env()}/examSet/get`, {'topic_id': urlParam.get('tid'), aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1])})
        if(fetch.length > 0){
            var newD = fetch.reduce(function(acc,curr){
                var name = acc.findIndex(function(item) {
                    return item.name === curr.name;
                })
                if(name === -1){
                    acc.push({'name': curr.name, 'sets': [curr]})
                }else{
                    acc[name].sets.push(curr)
                }
                return acc;
            }, []);
            setSets(newD)
        }
        setLoading(false);
    }
    const handleTutorial = () => {
        localStorage.removeItem('sip_trial')
        history.push('/biz/take-exam/tutorial?page=topics')
    }
    useEffect(() => {
        setTimeout(() => {
            fetchSets();
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
            <Box width="100%" height="100%">
                <Box p="40px 72px 24px 72px" borderBottom="1px solid #E5EBF6" sx={{boxShadow: '0px 2px 4px 0px #32464414'}}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{fontSize: '14px', color: '#7C9F83', mb: '10px'}}>
                        <Link underline="none" color="#7C9F83" href="#" onClick={() => history.push(`/biz/take-exam`)} sx={{fontSize: '14px', fontWeight: 400}}>Take Exam</Link>
                        <Link underline="none" color="#7C9F83" href="#" onClick={() => history.push(`/biz/take-exam`)} sx={{fontSize: '14px', fontWeight: 400, textOverflow: 'ellipsis', whiteSpace: 'noWrap', overflow: 'hidden', width: '120px', display: 'block'}}>{urlParam.get('sname')}</Link>
                        <Link underline="none" color="#7C9F83" href="#" onClick={() => history.push(`/biz/take-exam?page=topics&sid=${urlParam.get('sid')}&stid=${urlParam.get('stid')}&sname=${urlParam.get('sname')}`)} sx={{fontSize: '14px', fontWeight: 400, textOverflow: 'ellipsis', whiteSpace: 'noWrap', overflow: 'hidden', width: '120px', display: 'block'}}>{urlParam.get('tname')}</Link>
                        <Typography color="text.primary" sx={{fontSize: '14px', fontWeight: 400}}>Sets</Typography>
                    </Breadcrumbs>
                    <Box fontWeight={700} fontSize="24px">Sets</Box>
                </Box>
                <Box p="40px 72px">
                    <Button variant="outlined"> <Play fill="#107038" stroke="#107038"/><Typography color="#313131" sx={{ml:"8px"}} onClick={handleTutorial}>Tutorial</Typography></Button>
                    <Grid container spacing={2} sx={{mt: '24px'}}>
                    {
                        loading ? (
                            [0,1,2,3,5,6].map(k => (
                                <Grid key={k} item xs={12} md={4} lg={4}>
                                    <Skeleton variant="rounded" width={'100%'} height={'194px'} sx={{borderRadius: '12px', mb: 2}} />
                                </Grid>
                            ))
                        ) : (
                            sets.length > 0 &&
                                sets.map((set, k) => (
                                    <Grid key={k} item xs={12} md={4} lg={4}>
                                        <Box border="1px solid #E5EBF6" borderRadius="12px" p="16px">
                                            <Box fontSize="14px" fontWeight={600} color="#313131" mb="16px">Set {set.name}</Box>
                                            <Box bgcolor="#f6fbf9" borderRadius="6px">
                                                {
                                                    set.sets.map((s,i) => (
                                                        <Box key={i} >
                                                            <Box p="8px 12px" display="flex" justifyContent="space-between" alignItems="center">
                                                                <Box fontSize="14px" color="#000">{s.category_type === 1 ? 'Basic' : s.category_type === 2 ? 'Intermediate' : 'Advance'}</Box>
                                                                <Box display="flex">
                                                                    {
                                                                        s.esstatus !== null ? (
                                                                            <Button variant="outlined" sx={{mr: '10px', color: '#313131', fontSize: '14px', fontWeight: 500}} onClick={() => history.push(`/biz/take-exam?page=results&sid=${urlParam.get('sid')}&stid=${urlParam.get('stid')}&sname=${urlParam.get('sname')}&tid=${urlParam.get('tid')}&tname=${urlParam.get('tname')}&setId=${s.id}&setName=${s.name}&setCategory=${s.category_type}&esid=${s.esid}&type=exam&take=${s.take}`)}>Results</Button>
                                                                        ) : (
                                                                            <Button variant={i === 0 ? "contained" : "outlined"} color="primary" disabled={i === 0 ? (s.esstatus === null ? false : true) :  (set.sets[i-1].esstatus === null ? true : (set.sets[i-1].esstatus === 2 ? true : false)) } onClick={()=>handleTakeExam(s.id, s.name, s.category_type)}>Take Exam</Button>
                                                                        )
                                                                    }
                                                                    {
                                                                        s.esstatus === 2 &&
                                                                        <Button variant="outlined" sx={{color: '#11783C', fontSize: '14px', fontWeight: 500}} onClick={() => history.push(`/biz/take-exam?page=questionnaire&sid=${urlParam.get('sid')}&stid=${urlParam.get('stid')}&sname=${urlParam.get('sname')}&tid=${urlParam.get('tid')}&tname=${urlParam.get('tname')}&setId=${s.id}&setName=${s.name}&setCategory=${s.category_type}&esid=${s.esid}&type=exam&take=${s.take + 1}`)}>Retake</Button>
                                                                    }
                                                                </Box>
                                                            </Box>
                                                            {i !== (set.sets.length - 1) && <Divider sx={{border: "1px solid #ececec", mt: '8px', mb: '8px'}}/> }
                                                        </Box>
                                                    ))
                                                }
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))
                        )
                    }
                    </Grid>
                </Box>
                {
                    exam.dialog === 'SED' &&
                    <SED exam={exam} setExam={setExam}/>
                }
                {
                    exam.dialog === 'HTPTE' &&
                    <HTPTE exam={exam} urlParam={urlParam} setExam={setExam}/>
                }
            </Box>
    )
}