import { Box, InputLabel, TextField, MenuItem, Button, Skeleton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import moment from 'moment';
import { Waypoint } from 'react-waypoint';
import { rqx } from '../../../../core/request/API';
import { env } from '../../../../core/Env';
let hasValue = {};
export const Index = ({__SESSION}) => {
    const types = [{id: 0, value: 'Licensures'}, {id: 1, value: 'Certifications'}]
    const [input, setInput] = useState({type: null, from: undefined, to: undefined})
    const [isDisabled, setisDisabled] = useState(true)
    const [hasError, sethasError] = useState(null)
    const [users, setUsers] = useState({status: 'no-data', data: []})
    const [more, setMore] = useState(false)
    const handleInput = (value, name) => {
        setInput({...input, [name]: value})
        handleValidation(name, value)
    }
    const handleValidation = (name, value) => {
        hasValue[name] = value
        let d = Object.keys(hasValue).every((k) => hasValue[k] !== null)
        console.log(hasValue);
        if(Object.keys(hasValue).length === 3 && d){
            setisDisabled(false)
        }else{
            setisDisabled(true)
        }
    }
    const generateUsers = async() => {
        if(moment(input.from).format('YYYY-MM-DD') > moment(input.to).format('YYYY-MM-DD')){
            sethasError('!valid-date');
        }else{
            if(input.type === 0){
                fetchUsers(0)
            }else{
                setUsers({status: 'no-data', data: []})
            }
        }
    }
    const fetchUsers = async(lastId) => {
        const fetch = await rqx.get(`<<tool>>/${env() === 'local' ? 'dev' : env()}/user/getUsers`, {company_id: __SESSION.data.uaccess[0].company_id, tool_id: process.env.REACT_APP_TID_BIZ, 'start': moment(input.from).format('YYYY-MM-DD'), 'end': moment(input.to).format('YYYY-MM-DD'), 'lastId':lastId})
        if(fetch.msg === 'success'){
            setMore(fetch.data.length > 0 ? true : false)
            if(lastId === 0){
                setUsers({status: 'has-data', data: fetch.data})
            }else{
                setUsers({status: 'has-data', data: users.data.concat(fetch.data)})
            }
        }
    }
    const loadMore = async() => {
        let lastData = users.data[users.data.length - 1];
        let lastId = lastData.id;
        fetchUsers(lastId)
    }
    return (
        <>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="16px">
            <Box display="flex">
                <Box sx={{'& .MuiTextField-root': { mr: 1, width: '25ch' }}} mb="5px">
                    <InputLabel sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>Type</InputLabel>
                    <TextField id="outlined-select-currency" name="type" size="small" select value={input.type} onChange={(e)=>handleInput(e.target.value, 'type')}>
                        {
                            types.map((t,i) => (
                                <MenuItem key={i} value={t.id}>{t.value}</MenuItem>
                            ))
                        }
                    </TextField>
                </Box>
                <Box width="25ch" mr="10px">
                    <InputLabel sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>From:</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker slotProps={{ textField: { size: 'small' } }} value={input.from} inputFormat="d" onChange={(e)=>handleInput(e, 'from')} renderInput={(params) =><TextField size="small" name="from" {...params} margin="dense" />} />
                    </LocalizationProvider>
                </Box>
                <Box width="25ch" mr="10px">
                    <InputLabel sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>To:</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker slotProps={{ textField: { size: 'small' } }} inputFormat="d" value={input.to} onChange={(e)=>handleInput(e, 'to')}  renderInput={(params) =><TextField size="small" name="to" {...params} margin="dense" />} />
                    </LocalizationProvider>
                </Box>
            </Box>
            <Box mt="20px"><Button variant="contained" disabled={isDisabled} onClick={generateUsers}>Generate</Button></Box>
        </Box>
        <Box display="flex" justifyContent="space-between" color="#283745" fontWeight={600} p="16px">
            <Box width="25%">User No.</Box>
            <Box width="25%">Name</Box>
            <Box width="25%">Last Login</Box>
            <Box width="25%">Status</Box>
        </Box>
        {
            users.status === 'loading' ? (
                [0,1,2].map(i => (
                    <Skeleton key={i} variant="rounded" width="100%" height={60} />
                ))
            ) : (
                users.status === 'no-data' ? (
                    <Box width="100%" height="100px" border="1px solid #ebebeb" borderRadius="12px" display="flex" alignItems="center" justifyContent="center" fontSize="14px">
                        No data available.
                    </Box>
                ) : (
                    users.data.map((d,i) => (
                        <Box key={i} display="flex" border="1px solid #ebebeb" borderRadius="10px" p="16px" fontSize="14px" mb="12px">
                            <Box width="25%">{i+1}</Box>
                            <Box width="25%">{d.first_name +' '+d.last_name}</Box>
                            <Box width="25%">{moment(d.last_login, "YYYY-MM-DD h:mm:ss").fromNow()}</Box>
                            <Box width="25%" display="flex" alignItems="center">
                                <Box width="10px" height="10px" bgcolor={moment(d.last_login).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? "green" : "red"} borderRadius="100px"></Box>
                                <Box ml="10px">{moment(d.last_login).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? "Active" : "Not Active"}</Box>
                            </Box>
                        </Box>
                    ))
                )
            )
        } 
        {
            more &&
            <Waypoint onEnter={loadMore} />
        }
        </>
    )
}