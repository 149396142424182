import { Box, Button, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchActivities } from '../global/Functions';
import { Result } from './modals/Result'
import { List } from './List';
import { Retake } from './modals/Retake'
import { Success } from './modals/Success'
export const Index = ({urlParam, __SESSION}) => {
    const history = useHistory()
    const labels = ['No. of Taking', 'Action', 'Date Taken', 'Total Questions', 'Correct Answers', 'Correct Answers %', 'Removed Answers %',  'Status', 'Suggested Time', 'Time Spent'];
    const [loading, setLoading] = useState(true);
    const [isDialog, setIsDialog] = useState({open: false, name: null})
    const [overview, setOverview] = useState({open: false, loading: true, status: null, index: null, summary: [], sed: []})
    const [results, setResults] = useState([]);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            fetchActivities(overview, urlParam, __SESSION, setResults, setIsDialog, setOverview, setLoading);
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
        <Box width="100%" height="100%" >
            <Box p="42px 72px 0px 72px">
                <Button variant="outlined" onClick={()=>history.push(`/biz/take-exam?page=sets&sid=${urlParam.get('sid')}&stid=${urlParam.get('stid')}&sname=${urlParam.get('sname')}&tid=${urlParam.get('tid')}&tname=${urlParam.get('tname')}`)}>Back to Sets</Button>
                <Typography color="#313131" fontSize="24px" fontWeight={700} mb="24px" mt="24px">Results</Typography>
                <Typography color="#313131" fontSize="16px" fontWeight={600} mb="24px" mt="24px">{urlParam.get('sname')}</Typography>
                <Box display="flex" alignItems="center" mb="24px">
                    <Box border="1px solid #107038" bgcolor="#07a42a1a" color="#107038" fontSize="14px" fontWeight={400} p="2px 12px" borderRadius="100px" mr="16px">{urlParam.get('setCategory') === 1 ? 'Basic' : urlParam.get('setCategory') === 2 ? 'Intermediate' : 'Advance'}: Set {urlParam.get('setName')}</Box>
                    <Box fontSize="14px" fontWeight={400}>{urlParam.get('tname')}</Box>
                </Box>
            </Box>
            <Box p="10px 72px">
                <Box sx={{minWidth: '90%', overflowX: 'auto'}}>
                    <Box sx={{whiteSpace: 'nowrap', position: 'relative', width: '100%' }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" textAlign="center" bgcolor="#E9EEF7" p="10px" borderRadius="10px" fontSize="12px" mb={2}>
                            {
                                labels.map((l,i) => (
                                    <Box key={i}  width="10%">{l}</Box>
                                ))
                            }
                        </Box>
                    </Box>
                    {
                        loading ? (
                            [0,1].map(k => (
                                <Skeleton key={k} variant="rounded" width={'100%'} height={70} sx={{borderRadius: '10px', mt: 2, mb: 1}} />
                            ))
                        ) : (
                            <List examStatus={results.estatus} results={results.activities} isDialog={isDialog} setIsDialog={setIsDialog} overview={overview} setOverview={setOverview} urlParam={urlParam}/>
                        )
                    }
                </Box>
            </Box>
            {
                !loading &&
                <>
                    {
                        overview.open &&
                        <Result examStatus={results.estatus} overview={overview} setOverview={setOverview} urlParam={urlParam} totalTakes={results.activities.length} />
                    }
                    {
                        isDialog.name === 'retake' &&
                        <Retake isDialog={isDialog} overview={overview} setIsDialog={setIsDialog} urlParam={urlParam}/>
                    }
                    {
                        isDialog.name === 'success' &&
                        <Success isDialog={isDialog} setIsDialog={setIsDialog} urlParam={urlParam} results={results.activities}/>
                    }
                </>
            }
        </Box>
    )
}