import { Box, IconButton, Skeleton } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Settings1 } from '../../../../core/global/Icons';
ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export const DAU = ({graph, setSetting}) => {
    return (
        <Box bgcolor="#fff" borderRadius="12px" p="16px">
            {
                graph.status === 'loading' ? (
                    <Box bgcolor="#fff" borderRadius="12px">
                        <Box display="flex" justifyContent="space-between">
                            <Skeleton variant="text" width={230} sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="rounded" width={40} height={40} sx={{borderRadius: '100px'}}/>
                        </Box>
                        <Skeleton variant="rounded" width="100%" height={300} sx={{borderRadius: '12px', mt: '22px'}}/>
                    </Box>
                ) : (
                    <>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box>Daily Active User</Box>
                        <IconButton onClick={()=>setSetting({open: true})}><Settings1 fill="#525B6D" /></IconButton>
                    </Box>
                    <Box display="flex" justifyContent="center" position="relative" sx={{height: '315px'}} mb="24px">
                        <Bar 
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: false,
                                    tooltip: {
                                        displayColors: false,
                                    }
                                },
                                categoryPercentage: 0.8,
                                barPercentage: 0.7,
                                scales: {
                                        y: {
                                            min: 0,
                                            max: 100,
                                            grid: {
                                                drawBorder: false,
                                                borderDash: [8, 4],
                                                color: '#B5C0D7'
                                            },
                                            ticks: {
                                                callback: function(value) {
                                                    return value;
                                                },
                                                padding: 20,
                                                color: '#8496BB',
                                                stepSize: 20,
                                                font: {
                                                    family: 'Inter'
                                                }
                                            }
                                        },
                                        x: {
                                            grid: {
                                                display: false,
                                            }, 
                                            position: 'bottom',
                                            ticks: {
                                                font: {
                                                    family: 'Inter'
                                                }
                                            }
                                        },
                                    }
                                }}
                                data={{
                                    labels : graph.label.length > 0 ? graph.label : ['--', '--', '--', '--', '--', '--'], 
                                    datasets: [{label: 'Active', data: graph.active.length > 0 ? graph.active : ['50', '50', '50', '50', '50', '50'],backgroundColor: '#4A81FD', borderRadius: 5,borderSkipped: false,},{label: 'Not Active',data: graph.not_active.length > 0 ? graph.not_active : ['80', '80', '80', '80', '80', '80'],backgroundColor: '#FF5B5B',borderRadius: 5,borderSkipped: false,}]
                                }}
                        />
                    </Box>
                    </>
                )
            }
        </Box>
    )
}