import { Box, Button, Grid } from '@mui/material'
import { useHistory } from 'react-router-dom';
import NoRecent from '../../../../../assets/images/takeExam/no_recent.png';
export const Recent = ({num, recent, urlParam}) => {
    const history = useHistory()
    return (
        recent.length === 0 ? (
            <Grid item xs={12} md={12} lg={12}>
                <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' height="400px" width="100%">
                    <Box display="flex" flexDirection="column" justifyContent="center">
                        <img src={NoRecent} alt="icon"/>
                        <Box color="#313131" fontSize="14px" fontWeight={400} textAlign="center" mt="24px">No recent topic.</Box>
                    </Box>
                </Box>
            </Grid>
        ) : (
            recent.map((r,k) => (
                <Grid key={k} item xs={12} md={12} lg={4}>
                    <Box border="1px solid" borderColor={r.isDisabled === undefined ? '#E5EBF6' : (!r.isDisabled ? '#E5EBF6' : '#F6F9FF')} borderRadius="12px" p="32px" sx={{bgcolor: r.gr > 0 ? (r.isDisabled === undefined ? '#fff' : (!r.isDisabled ? '#fff' : '#F6F9FF')) : '#F6F9FF'}}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb="24px">
                            <Box fontSize="14px" fontWeight={400} lineHeight="22px" width="70%" height="70px" display="flex" alignItems="center">{r.name}</Box>
                            <Button variant="outlined" onClick={() => history.push(`/biz/take-exam?page=sets&sid=${urlParam.get('sid')}&stid=${urlParam.get('stid')}&sname=${urlParam.get('sname')}&tid=${r.id}&tname=${r.name}`)} disabled={r.isDisabled === undefined ? false : (!r.isDisabled ? false : true)} sx={{ '&:disabled':{background: '#fff', color: '#256EFF', border: '1px solid #CCE0FF'} }}>Take</Button>
                        </Box>
                        <Box display="flex" mb="16px">
                            {
                                num.map((no, k) => (
                                    <Box key={k} bgcolor="#E5EBF6" mr="2px" height="4px" width="20%" display="flex">
                                        <Box bgcolor={r.isDisabled === undefined ? '#008F5D' : (!r.isDisabled ? '#008F5D' : '#bac7df')} width={`${k===0 ? (Math.round(r.gr) > no ? 100 : Math.round(r.gr)/no*100) : (Math.round(r.gr) > num[k-1] ? (Math.round(r.gr)-num[k-1])/20*100 : 0)}%`} height="4px" sx={{transition: "width 2s"}}></Box>
                                    </Box>
                                ))
                            }
                        </Box>
                        <Box display="flex" justifyContent="space-between" fontWeight={400} fontSize="12px" >
                            <Box display="flex">
                                <Box color={r.gr > 0 ? (r.isDisabled === undefined ? '#008F5D' : (!r.isDisabled ? '#008F5D' : '#8496BB')) : '#8496BB'} mr="12px">{Math.round(r.gr)}%</Box>
                                <Box color="#8496BB">Percentage</Box>
                            </Box>
                            <Box bgcolor={r.gr >= 74 ? '#f7fcfa' : r.gr > 0 ? '#FFFDE7' : "#E5EBF6"} borderRadius="100px" color={r.gr >= 74 ? '#008F5D' : r.gr > 0 ? '#F57F17' : "#092D77"} border={r.gr >= 74 ? '' : r.gr > 0 ? '1px solid #F6EDE6' : ''} fontSize="12px" fontWeight={400} p="2px 8px">{r.gr >= 74 ? 'Passed' : (r.gr > 0 ? 'Taken' : 'Not yet Taken')}</Box>
                        </Box>
                    </Box>
                </Grid>
            ))
        )
        
    )
}