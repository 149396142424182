import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SEDImage from '../../../../../assets/images/takeExam/SED.png';
export const SED = ({exam, setExam}) => {
    return (
        <Dialog open={exam.isDialog}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" PaperProps={{ sx: { borderRadius: "12px", width:'468px' } }}>
            <DialogTitle id="alert-dialog-title">
                <Box display="flex" justifyContent="flex-end">
                    <Box sx={{cursor: 'pointer'}} onClick={()=>setExam({...exam, isDialog: false, dialog: null})}><ClearIcon sx={{color: '#8496BB'}}/></Box>
                </Box>
                <Box display="flex" justifyContent="center">
                    <img src={SEDImage} alt="icon" height="100%"/>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box fontSize="16px" fontWeight={600} color="#313131" mb="20px">Pass the CPA board exam with 10x the probability using SED Marketing Technique </Box>
                <Box fontSize="12px" fontWeight={400} color="#313131" mb="24px">Classify and mark your answers according to the following degree of difficulty</Box>
                <Box fontSize="14px" fontWeight={600} color="#008F5D">SURE</Box>
                <Box fontSize="14px" fontWeight={400} color="#313131" mb="16px">You are 100% confident in your answer</Box>
                <Box fontSize="14px" fontWeight={600} color="#0070EF">EASY</Box>
                <Box fontSize="14px" fontWeight={400} color="#313131" mb="16px">You are familiar with questions but had second thoughts about the correct answers.</Box>
                <Box fontSize="14px" fontWeight={600} color="#FA3E3E">DIFFICULT</Box>
                <Box fontSize="14px" fontWeight={400} color="#313131">You have no idea how to answer the questions.</Box>
            </DialogContent>
            <DialogActions sx={{display: 'block', width: '100%', textAlign: 'right', p: '20px'}}>
                <Button variant="contained" onClick={()=>setExam({...exam, isDialog: true, dialog: 'HTPTE'})}>Continue</Button>
            </DialogActions>
        </Dialog>
    )
}