import { Avatar, Box, Divider, Grid } from '@mui/material';
import { Link } from 'react-router-dom'
import { DAU } from './DAU'
import { useState, useEffect, useContext } from 'react';
import { Setting } from './drawers/Setting';
import { ToolContext } from '../../../../core/context/ToolContext';
import star from '../../../../assets/images/home/star.png'
import PSLLogo from '../../../../assets/images/logos/logo_v1.png'
import { rqx } from '../../../../core/request/API';
import { env } from '../../../../core/Env';
import moment from 'moment'
export const Index = () => {
    const { tool_state } = useContext(ToolContext)
    const __SESSION = tool_state.__SESSION.data
    const [setting, setSetting] = useState({open: false})
    const [top5, setTop5] = useState({status: 'loading', data: []})
    const [graph, setGraph] = useState({status: 'loading', label: [], active: [], not_active: []})
    const [users, setUsers] = useState(0)
    const fetchTop5 = async() => {
        if(__SESSION.uaccess.length > 0){
            if(__SESSION.uaccess[0].company_id !== null){
                const getRanking = await rqx.get(`<<tool>>/${env() === 'local' ? 'dev' : env()}/user/getRankings`, {company_id: __SESSION.uaccess[0].company_id, tool_id: process.env.REACT_APP_TID_BIZ, limit: 5, skip: 0, platform: 'biz'})
                if(getRanking.msg === 'success'){
                    setTop5({status: 'has-data', data: getRanking.data})
                }
            }
        }
    }
    const totalUsers = async() => {
        const getTotal = await rqx.get(`<<tool>>/${env() === 'local' ? 'dev' : env()}/user/totalUsers`, {company_id: __SESSION.uaccess[0].company_id, tool_id: process.env.REACT_APP_TID_BIZ, platform: 'biz'})
        if(getTotal.msg === 'success'){
            setUsers(getTotal.data)
            fetchDAU(getTotal.data);
        }
    }
    const fetchDAU = async(totalUsers) => {
        let start =  moment().subtract(3, 'days').format('Y-MM-DD');
        let end = moment().add(3, 'days').format('Y-MM-DD');
        const getData = await rqx.get(`<<tool>>/${env() === 'local' ? 'dev' : env()}/user/getDAU`, {company_id: __SESSION.uaccess[0].company_id, tool_id: process.env.REACT_APP_TID_BIZ, platform: 'biz', start: moment(start).format('YYYY-MM-DD'), end: moment(end).format('YYYY-MM-DD'),})
        if(getData.msg === 'success'){
            let labels = [];
            let a = [];
            let na = []
            let dateStart =  moment().subtract(3, 'days').format('Y-MM-DD');
            for (let i = 0; i < 6; i++) {
                let s =  moment(dateStart).add(i, 'days').format('Y-MM-DD');
                if(getData.data.length > 0){
                    let filter = getData.data.filter(f => f.date === s)
                    a.push(filter.length > 0 ? filter[0].total : 0)
                    na.push(filter.length > 0 ? (1-filter[0].total): 1)
                }else{
                    a.push(0)
                    na.push(totalUsers)
                }
                labels.push(moment(s).format('MMM D, YYYY'))
            }
            setGraph({...graph, status: 'has-data', label: labels, active: a, not_active: na})
        }
    }
    useEffect(() => {
        fetchTop5();
        totalUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Box height="100%"  pt="64px">
            <Box borderRight="1px solid #E5EBF6" p="41px 56px">
                <Box fontWeight={700} fontSize="24px" lineHeight="38px" mb="6px">Dashboard (Super Admin)</Box>
                <Box fontSize="14px" fontWeight={400} color="#313131" mb="15px">Extraordinary & Heroic Day Hero Users!</Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <Box bgcolor="#fff" borderRadius="12px" p="16px" sx={{height: '408px'}}>
                            <Box textAlign="center" mb="12px">
                                <Box fontSize="14px">Total no. of Reviewees</Box>
                                <Box mt="20px" fontSize="32px" fontWeight={600}>{users}</Box>
                            </Box>
                            <Divider />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} lg={12}>
                                    <Box fontSize="14px" mb="10px" mt="10px">Licensures</Box>
                                    <Box bgcolor="#f0f6f3" borderRadius="12px" p="16px" display="flex" justifyContent="space-between" mb="5px">
                                        <Box fontSize="14px">Accounting</Box>
                                        <Box fontSize="14px" fontWeight={600}>{users}</Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={8}>
                        <DAU graph={graph} setSetting={setSetting} __SESSION={__SESSION} setGraph={setGraph}/>
                    </Grid>
                </Grid>
                <Box bgcolor="#fff" borderRadius="12px" p="16px" mt="20px">
                    <Box display="flex" justifyContent="space-between" mb="24px">
                        <Box fontWeight="700">Leaderboard</Box>
                        <Link style={{color: '#004CFF'}} href="#" to="/leaderboard">See all</Link>
                    </Box>
                    <Box display="flex" justifyContent="space-around" p="12px 32px" fontSize="13px" fontWeight={600} border="1px solid #E5EBF6" bgcolor="#F6F9FF" borderRadius="6px">
                        <Box width="30%">Rank</Box>
                        <Box width="35%" textAlign="left">Name</Box>
                        <Box width="35%" textAlign="center">Points</Box>
                    </Box>
                    {
                        top5.status === 'loading' ? (
                            <></>
                        ) : (
                            top5.data.map((d,i) => (
                                <Box key={i} display="flex" justifyContent="space-between" alignItems="center" padding="12px 32px">
                                    <Box width="30%" fontSize="13px" fontWeight={600} mr="5px" display="flex">{i+1}</Box>
                                    <Box width="35%" display="flex" alignItems="center">
                                        <img src={(JSON.parse(d.profile).profile === '' || JSON.parse(d.profile).profile === undefined || JSON.parse(d.profile).profile === null || JSON.parse(d.profile).profile === 'unknown') ? PSLLogo : JSON.parse(d.profile).profile} height="28" alt="profile" width="28" style={{borderRadius: '100px', border: i === 0 ? '2px solid #2EAE84' :   i === 1 ? '2px solid #5B8DFD' : i === 2 ? '2px solid #FF6B6B' : '2px solid #DEE2EA'}}/>
                                        <Box ml="5px" fontSize="13px">{d.first_name+' '+d.last_name}</Box>
                                    </Box>
                                    <Box width="35%" fontSize="13px" display="flex" justifyContent="center"> 
                                        <Avatar src={star} alt="icon" sx={{height: 16, width: 16, mr: '5px'}}/> 
                                        10
                                    </Box>
                                </Box>
                            ))
                        )
                    }
                </Box>
            </Box>
            {
                setting.open && <Setting graph={graph} setting={setting} setSetting={setSetting} __SESSION={__SESSION} setGraph={setGraph} users={users}/>
            }
        </Box>
    )
}