import { Box, Button, Divider, Dialog, DialogActions, DialogTitle, DialogContent, IconButton } from '@mui/material';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Close } from '../../../../../core/global/Icons';
import { Graph } from '../../global/Graph';
export const Result = ({examStatus, overview, setOverview, urlParam, totalTakes}) => {
    const history = useHistory()
    const handleRetake = () => {
        history.push(`/biz/take-exam?page=questionnaire&sid=${urlParam.get('sid')}&stid=${urlParam.get('stid')}&sname=${urlParam.get('sname')}&tid=${urlParam.get('tid')}&tname=${urlParam.get('tname')}&setId=${urlParam.get('setId')}&setName=${urlParam.get('setName')}&setCategory=${urlParam.get('setCategory')}&type=exam&take=${overview.summary.take + 1}&etid=${urlParam.get('etid')}&esid=${urlParam.get('esid')}&eaid=${urlParam.get('eaid')}&from=exam`)
    }
    return (
        <Dialog open={overview.open} hideBackdrop={true} fullWidth maxWidth={'lg'} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" sx={{"& .MuiDialog-container": {justifyContent: 'flex-end'}}} PaperProps={{ sx: { borderRadius: "20px", border: '1px solid #E5EBF6', boxShadow: 'none', margin: 'unset', width:'602px'} }}>
            <DialogTitle id="alert-dialog-title">
                <Box sx={{ml: 'auto', mb: '14px'}}>
                    <IconButton sx={{border: '1px solid #E5EBF6', width: '20'}} onClick={()=>setOverview({...overview, open: false})}><Close sx={{color: '#0070EF'}}/></IconButton>
                </Box>
                <Box sx={{color: '#313131', fontWeight: 700, fontSize: '14px'}} mb="7px">{urlParam.get('sname')}</Box>
                <Box fontSize="12px" fontWeight={400}>{urlParam.get('tname')}</Box>
            </DialogTitle>
            <DialogContent sx={{maxHeight: '581px', overflowY: 'auto'}}>
                <Box border="1px solid #E5EBF6" borderRadius="12px" p="16px" mb="16px">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box width="20%">
                            <Graph percentage={Math.round(overview.summary.score/overview.summary.tq*100)}/>
                        </Box>
                        <Box fontSize="14px" fontWeight={400} ml="16px">
                            {
                                ((overview.summary.score/overview.summary.tq*100) >= 75 && overview.summary.alloted_time >= overview.summary.time_spent) ? (
                                    <>Good job! You take the passing score and you finished the exam quickly</>
                                ) : ((overview.summary.score/overview.summary.tq*100) >= 75 && overview.summary.alloted_time <= overview.summary.time_spent) ? (
                                    <>Good job! You take the passing score but it took you awhile to finish the exam.</>
                                ) : ((overview.summary.score/overview.summary.tq*100) <= 74 && overview.summary.alloted_time >= overview.summary.time_spent) ? (
                                    <>Unfortunately, you did not take the passing score, but you finished the exam quickly.</>
                                ) : (
                                    <>Unfortunately, you did not take the passing score but it took you awhile to finish the exam.</>
                                )
                            }
                        </Box>
                    </Box>
                </Box>
                <Box border="1px solid #E5EBF6" borderRadius="12px" p="16px" fontSize="14px" mb="16px">
                    <Box display="flex" justifyContent="space-between" mb="6px">
                        <Box width="50%">No. of Takes</Box><Box fontWeight={600} width="50%" textAlign="left">{overview.summary.take}</Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mb="6px">
                        <Box width="50%">Date Take</Box><Box fontWeight={600} width="50%" textAlign="left">{moment(overview.summary.created_at).format('L')}</Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mb="6px">
                        <Box width="50%">Total Question Taken</Box><Box fontWeight={600} width="50%" textAlign="left">{overview.summary.tq}</Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mb="6px">
                        <Box width="50%">Your Score</Box><Box fontWeight={600} width="50%" textAlign="left">{overview.summary.score}</Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <Box width="50%">Status</Box><Box width="50%"><Box component="span" borderRadius="100px" bgcolor={overview.summary.status === 2 ? '#FFFDE7' : '#f7fcfa'} p="4px 8px" color={overview.summary.status === 2 ? '#FA3E3E' : '#008F5D'}>{overview.summary.status === 2 ? 'Failed' : 'Passed'}</Box></Box>
                    </Box>
                </Box>
                <Box border="1px solid #E5EBF6" borderRadius="12px" p="16px" fontSize="14px" mb="16px">
                    <Box fontSize="14px" fontWeight={600} mb="6px">SED Marking Technique</Box>
                    <Box display="flex" justifyContent="space-between" textAlign="center" alignItems="center" p="10px" mb="10px" fontSize="12px">
                        <Box width="25%">Category</Box>
                        <Box width="25%">Total Questions</Box>
                        <Box width="25%">Correct</Box>
                        <Box width="25%">Wrong</Box>
                    </Box>
                    <Divider sx={{mb: '8px'}}/>
                    {
                        overview.sed.map((s,i) => (
                            <Box key={i} display="flex" justifyContent="space-between" textAlign="center" alignItems="center" sx={{bgcolor: i === 0 ? '#E8F5E9' : i === 1 ? '#CCE0FF' : '#FFEBEE'}} p="10px" borderRadius="10px" mb="10px" fontSize="12px">
                                <Box width="25%" fontWeight={600} textAlign="left" color={i === 0 ? '#008F5D' : i === 1 ? '#0070EF' : '#FA3E3E'}>{i === 0 ? 'SURE' : i === 1 ? 'EASY' : 'DIFFICULT'}</Box>
                                <Box width="25%">{s.total}</Box>
                                <Box width="25%">{s.correct}</Box>
                                <Box width="25%">{s.total - s.correct}</Box>
                            </Box>
                        ))
                    }
                    <Box display="flex" justifyContent="space-between" textAlign="center" alignItems="center" bgcolor="#F6F9FF" p="10px" borderRadius="10px" mb="10px" fontSize="12px" fontWeight={400}>
                        <Box width="25%" textAlign="left">Total</Box>
                        <Box width="25%">{overview.summary.tq}</Box>
                        <Box width="25%">{overview.summary.score}</Box>
                        <Box width="25%">{overview.summary.tq - overview.summary.score}</Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions sx={{display: 'flex', justifyContent: 'center' }}>
                {
                    (totalTakes === overview.summary.take && examStatus === 2) &&
                    <Box sx={{ m: 1, position: 'relative', }}>
                        <Button variant="contained" onClick={handleRetake}>Retake</Button>
                    </Box>
                }
            </DialogActions>
        </Dialog>
    )
}