import { Box, Button, Tabs, Tab, Skeleton } from '@mui/material';
import { makeStyles} from '@mui/styles';
import { SelectCategory } from './modals/SelectCategory';
import { useState } from 'react';
const useStyles = makeStyles({
    tabs: {
      "& .MuiTabs-indicator": {
        backgroundColor: "orange",
        height: 0,
      },
      "& .MuiTab-root.Mui-selected": {
        backgroundColor: '#F6F9FF',
        color: '#333'
      }
    }
  })
export const Question = ({category, exam, loading, setExam, setCategory, setQuestions, setQuestion, questions, question, fetchQuestions, urlParam}) => {
    const classes = useStyles();
    const [isDisabled, setisDisabled] = useState(false)
    const handleChoice = (letter, key) => {
        let answer = letter.replace(/[.,\s]/g, '')
        let updatedQuestions = questions.map(q => (
            q.id === question.id ? {...q, u_answer: answer, u_result: (answer === question.answer ? 1 : 2)} : q
        ))
        setQuestions(updatedQuestions);
        setQuestion({...question, u_answer: answer})
        setCategory({...category, isOpen: true, index: key})
        setisDisabled(true)
    }
    const handleSubmitExam = () => {
        let na = questions.findIndex(q => !q.hasOwnProperty('u_answer'))
        if(na >= 0){
            setExam({...exam, status: 'not-answered', notAnswered: na+1, isDialog: true})
        }else{
            setExam({...exam, status: 'submit', isDialog: true})
        }
    }
    const handleChange = (event, newValue) => {
        fetchQuestions(newValue);
    }
    return (
        loading ? (
            <Skeleton variant="rounded" width={'100%'} height={300} sx={{borderRadius: '10px', mb: 2}} />
        ) : (
            <>
            <Box border="1px solid #E5EBF6" p="24px 24px 0px 24px" borderRadius="6px" mb="24px">
                <Box mb="24px">Question no. {exam.no + 1}</Box>
                <Box dangerouslySetInnerHTML={ { __html:  question.question}}></Box>
                <Box pt="10px" pb="20px" pl="20px" pr="20px">
                    {
                        JSON.parse(question.choices).map((choice, key) => (
                            <Box key={key} display="flex" mb="16px" alignItems="center" sx={{cursor: 'pointer'}} onClick={()=>handleChoice(choice.letter, exam.no)}>
                                <Box borderRadius="100px" border="1px solid #E5EBF6" bgcolor={question.u_answer !== null ? (question.u_answer === choice.letter ? '#E5EBF6' : '') : ''} p="2px 9px" mr="16px" fontWeight={600}>{choice.letter}</Box>
                                <Box borderRadius="6px" border="1px solid #E5EBF6" bgcolor={question.u_answer !== null ? (question.u_answer === choice.letter ? '#E5EBF6' : '') : ''} p="10px" fontSize="14px" width="100%">{choice.value}</Box>
                            </Box>
                        ))
                    }
                </Box>
                {
                    category.isOpen ? (
                        <SelectCategory category={category} setCategory={setCategory} exam={exam} setExam={setExam} urlParam={urlParam} questions={questions} question={question} setQuestion={setQuestion} fetchQuestions={fetchQuestions} setisDisabled={setisDisabled}/>
                    ) : (
                        question.u_category !== undefined &&
                        <Box sx={{color: '#fff', ml: '20px', fontSize: '14px', pl: '12px', pr: '12px', pt: '8px', pb: '8px', borderTopRightRadius: '6px', borderTopLeftRadius: '6px', bgcolor: question.u_category === 1 ? '#008F5D' : question.u_category === 2 ? '#3490dc' : '#e3342f', width: '80px', textAlign: 'center'}}>{question.u_category === 1 ? 'Sure' : question.u_category === 2 ? 'Easy' : 'Difficult'}</Box>
                    )
                    
                }
            </Box>
            
            <Box sx={{ position: 'static', bottom: 0}} display="flex" justifyContent="space-between" alignItems="center" >
                <Box display="flex" justifyContent="space-between">
                    <Tabs value={exam.no} className={classes.tabs} onChange={handleChange} variant="scrollable" scrollButtons aria-label="scrollable prevent tabs example" sx={{display: 'flex', alignItems: 'center'}}>
                        {
                            exam.aq.map((q,k) => (
                                <Tab key={k} label={k+1} sx={{maxWidth: '0px', minWidth: '0px', minHeight: '0px', height: '12px', borderRadius: '100px', border: '1px solid #E5EBF6', bgcolor: (q.u_category === undefined ? '#fff' : '#0070EF'), color: (q.u_category === undefined ? '#313131' : '#fff'), mr: '8px', p: '12px 13px'}} disabled={isDisabled}/>
                            ))
                        }
                    </Tabs>
                </Box>
                <Box display="flex" alignItems="center">
                    <Button variant="contained" color="info" onClick={()=>handleSubmitExam()} disabled={exam.tq === questions.filter(q => q.hasOwnProperty('u_answer')).length ? false : true}>Submit</Button>
                </Box>
            </Box>
            
            </>
        )
    )
}