import { Box, Button, Dialog, DialogActions, DialogTitle, DialogContent, Stack } from '@mui/material';
import CafguSuccess from '../../../../../assets/images/cafgu/cafgu-success-confetti.png';
import { useHistory } from 'react-router-dom';
export const Success = ({exam, urlParam, result}) => {
    const history = useHistory()
    const handleNotNow = () => {
        history.push(`/take-exam?page=results&sid=${urlParam.get('sid')}&stid=${urlParam.get('stid')}&sname=${urlParam.get('sname')}&tid=${urlParam.get('tid')}&tname=${urlParam.get('tname')}&setId=${urlParam.get('setId')}&setName=${urlParam.get('setName')}&setCategory=${urlParam.get('setCategory')}&type=${urlParam.get('type')}&take=${parseInt(urlParam.get('take')) + 1}&etid=${result.etid}&esid=${result.esid}&eaid=${result.eaid}&from=exam`)
    }
    return (
        <Dialog open={exam.isDialog} fullWidth maxWidth={'xs'} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                <Box display="flex" justifyContent="center">
                    <img src={CafguSuccess} alt="icon" height="100%"/>
                </Box>
            </DialogTitle>
            <DialogContent sx={{textAlign: 'center'}}>
                <Box fontSize="24px" fontWeight={700} color="#313131" mb="17px" textAlign="center">Congratulations!</Box>
                <Box mt="10px" mb="10px" color="#000" fontSize="16px">You have completed the <Box component="span" fontWeight="bold">Set {urlParam.get('setName')}</Box> of <Box component="span" fontWeight="bold">{urlParam.get('tname')}</Box> with <Box component="span" fontWeight="bold" color="green">100% Correct and Sure.</Box></Box>
                <Box color="#000" fontSize="16px">Do you want to continue another set of {urlParam.get('sname')} topic?</Box>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={2} display="flex" justifyContent="center" width="100%" mb="15px">
                    <Button variant="contained" color="secondary" onClick={handleNotNow}>Not Now</Button>
                    <Button variant="contained" onClick={()=>history.push(`/take-exam?page=sets&sid=${urlParam.get('sid')}&stid=${urlParam.get('stid')}&sname=${urlParam.get('sname')}&tid=${urlParam.get('tid')}&tname=${urlParam.get('tname')}`)}>Take Now</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}