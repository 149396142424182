import { Box, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment'
import { Calendar2 } from '../../../../../core/global/Icons';
export const DBTE = ({myPlan}) => {
    const history = useHistory()
    const [calendar, setCalendar] = useState([])
    useEffect(() => {
        let start = moment().subtract(4, 'days').format('MM-DD-YYYY')
        let data = [];
        for (let i = 1; i < 8; i++) {
            data.push({'day': moment(start).add(i, 'days').format('dd'), 'no': moment(start).add(i, 'days').format('DD')})
        }
        setCalendar(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Box p="14px">
            <Box display="flex" justifyContent="space-between" alignItems="center" mb="14px">
                <Box fontWeight={600}>Examination Date</Box>
                <IconButton onClick={()=>history.push('?account=change-plan')}>
                    <Calendar2 fill="#525B6D" />
                </IconButton >
            </Box>
            <Box fontSize="16px" fontWeight={600} color="#313131" mb="5px">{moment().format('MMMM, YYYY')}</Box>
            <Box fontWeight={400} color="#107038" fontSize="14px" mb="16px">{myPlan.plan === null ? 0 : myPlan.plan.days} days remaining</Box>
            <Box display="flex" justifyContent="space-between">
                {
                    calendar.map((c,k) => (
                        <Box key={k} p="8px 10px" borderRadius="6px" bgcolor={k===3 ? '#165320' : ''}>
                            <Box mb="5px" fontSize="14px" fontWeight={600} color={(k===0 || k===6) ? 'rgba(22, 83, 32, 0.6)' : (k===1 || k===5 ? 'rgba(22, 83, 32, 0.8)' : (k===2 || k===4 ? 'rgba(22, 83, 32, 1)' : '#fff'))}>{c.day.toUpperCase()}</Box>
                            <Box fontSize="14px" fontWeight={600} color={(k===0 || k===6) ? 'rgba(49, 49, 49, 0.4)' : (k===1 || k===5 ? 'rgba(49, 49, 49, 0.6)' : (k===2 || k===4 ? 'rgba(49, 49, 49, 1)' : '#fff'))} textAlign="center">{c.no}</Box>
                        </Box>
                    ))
                }
            </Box>
        </Box>
    )
}