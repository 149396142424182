import { Box, Button} from '@mui/material';
export const SelectCategory = ({category, setCategory, exam, urlParam, questions, question, setQuestion, fetchQuestions, setisDisabled}) => {
    const handleCategory = async(value) => {
        let index = questions.map(function (q) { return q.id; }).indexOf(question.id)
        questions[index]['u_category'] = value;
        let indexAQ = exam.aq.map(function (q) { return q.id; }).indexOf(question.id)
        exam.aq[indexAQ]['u_category'] = value;
        setCategory({index: null, isOpen: false});
        setQuestion({...question, u_answer: questions[index].u_answer, u_category: value})
        let answered = questions.filter(q => q.hasOwnProperty('u_answer'));
        if(answered.length !== exam.tq){
            let na = exam.aq.findIndex(q => !q.hasOwnProperty('u_category'))
            fetchQuestions(na)
        }
        setisDisabled(false)
    }
    return (
        <Box>
            <Box mb="5px">Rate your answer:</Box>
            <Box display="flex">
                {
                    urlParam.get('take') >= 3 ? (
                        <>
                        <Button variant="contained" fullWidth  sx={{mb: '20px', mr: '10px', bgcolor: '#2EAE84', borderRadius: '6px', p: '16px', '&:hover': {bgcolor: '#2EAE84'}}} onClick={()=>handleCategory(1)}>SURE</Button>
                        <Button variant="contained" fullWidth sx={{mb: '20px', mr: '10px', bgcolor: '#0070EF', borderRadius: '6px', p: '16px', '&:hover': {bgcolor: '#0070EF'} }} onClick={()=>handleCategory(2)}>EASY</Button>
                        </>
                    ) : (urlParam.get('take') >= 5) ? (
                        <Button variant="contained" fullWidth  sx={{mb: '20px', bgcolor: '#2EAE84', borderRadius: '6px', p: '16px', '&:hover': {bgcolor: '#2EAE84'}}} onClick={()=>handleCategory(1)}>SURE</Button>
                    ) : (
                        <>
                        <Button variant="contained" fullWidth  sx={{mb: '20px', bgcolor: '#2EAE84', borderRadius: '6px', p: '16px', '&:hover': {bgcolor: '#2EAE84'}}} onClick={()=>handleCategory(1)}>SURE</Button>
                        <Button variant="contained" fullWidth sx={{mb: '20px', ml: '10px', mr: '10px', bgcolor: '#0070EF', borderRadius: '6px', p: '16px', '&:hover': {bgcolor: '#0070EF'} }} color="primary" onClick={()=>handleCategory(2)}>EASY</Button>
                        <Button variant="contained" fullWidth sx={{mb: '20px', bgcolor: '#FA3E3E', borderRadius: '6px', p: '16px', '&:hover': {bgcolor: '#FA3E3E'}}} onClick={()=>handleCategory(3)}>DIFFICULT</Button>
                        </>
                    )
                }
            </Box>
        </Box>
    )
}