import { Box, IconButton } from "@mui/material"
import { useContext } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ToolContext } from '../../../core/context/ToolContext';
import { ArrowLeft } from '../../../core/global/Icons'
export const Support = () => {
    const { tool_state } = useContext(ToolContext)
    const { __SESSION } = tool_state
    const redirect = () => {
        window.location.href='/psl/profile';
    }
    return (
        <Box p="16px" height="100%" sx={{background: 'linear-gradient(183.21deg, #4CA7FB -22.36%, #F6F9FF 19.68%, #F6F9FF 97.35%)'}}>
            <Box display="flex" alignItems="center" mb="40px">
                <IconButton onClick={()=>redirect()}><ArrowLeft /></IconButton>
                <Box width="90%" textAlign="center" fontSize="18px" fontWeight={600}>Support</Box>
            </Box>
            <Box fontSize="48px" fontWeight={700} mb="24px">Hi {__SESSION.data.pinfo[0].first_name}, Do you have any concern?</Box>
            <Box fontSize="12px" fontWeight={600} display="flex" alignItems="center" color="#0070EF">Click that chat button below to get started <ArrowDownwardIcon fontSize="small"/></Box>
        </Box>
    )
}