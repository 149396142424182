import { Box, IconButton, Skeleton } from '@mui/material';
import { Settings1 } from '../../../../../core/global/Icons';
import {Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Filler,Legend} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Filler,Legend);
export const AQR = ({aqReport, setSettings}) => {
    return (
        <>
        {
            aqReport.stat === 'loading' ? (
                <Box bgcolor="#fff" borderRadius="12px" padding="20px" mt="32px">
                    <Box display="flex" justifyContent="space-between">
                        <Skeleton variant="text" width={230} sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="rounded" width={40} height={40} sx={{borderRadius: '100px'}}/>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Skeleton variant="text" width={50} sx={{ fontSize: '15px' }} />
                    </Box>
                    <Skeleton variant="rounded" width="100%" height={300} sx={{borderRadius: '12px', mt: '22px'}}/>
                </Box>
            ) : (
                <>
                    <Box display="flex" justifyContent="space-between" mb="10px" alignItems="center">
                        <Box fontSize="14px" fontWeight={600}>Answered Questions Report</Box>
                        <IconButton sx={{width: '40px', height: '40px'}} onClick={()=>setSettings({open: true, for: 'AQR'})} disabled={aqReport.stat === 'no-exam' ? true : false}>
                            <Settings1 fill="#525B6D" />
                        </IconButton >
                    </Box>
                    <Box display="flex" justifyContent="center" position="relative">
                        {
                            aqReport.stat === 'no-exam' &&
                            <Box bgcolor="#f0f6f3d1" position='absolute' height="274px" display="flex"  sx={{width: {'lg': '95%', 'md': '90%'}, ml: '65px', mt: '21px'}} justifyContent="center" alignItems="center" flexDirection='column' borderRadius="12px" >
                                <Box textAlign="center">
                                    <Box mt={2} mb={2} fontWeight={600}>No Exam taken yet.</Box>
                                </Box>
                            </Box>
                        }
                        <Box height="300px" width="100%">
                            <Box textAlign="center" fontSize="12px" fontWeight={400}>{aqReport.interval}</Box>
                            <Line 
                                data={{
                                    labels: aqReport.label,
                                    datasets: [
                                        {
                                            fill: true,
                                            data: aqReport.data.length > 0 ? aqReport.data : [50, 400, 300, 230,150,420],
                                            borderColor: "#0099FF",
                                            backgroundColor:  ({chart: {ctx}}) => {
                                                const gradient = ctx.createLinearGradient(0, 0, 0, 230);
                                                gradient.addColorStop(0, 'rgba(0, 153, 255, 1)');   
                                                gradient.addColorStop(1, 'rgba(0, 153, 255, 0)');
                                                return gradient;
                                            },
                                            tension: 0.6,
                                            pointHitRadius: 70
                                        },
                                    ],
                                }}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                        title: {
                                            display: false,
                                        },
                                        tooltip: {
                                            callbacks: {
                                                title: function(t){
                                                    return t[0].parsed.y + ' Answered Questions';
                                                },
                                                label: function(v){
                                                    
                                                }
                                            },
                                            displayColors: false,
                                        }
                                    },
                                    scales: {
                                        y: {
                                            min: 0,
                                            max: 500,
                                            grid: {
                                                drawBorder: false,
                                                borderDash: [8, 4],
                                                color: '#B5C0D7'
                                            },
                                            ticks: {
                                                padding: 20,
                                                color: '#000',
                                                stepSize: 100,
                                                font: {
                                                    family: 'Inter'
                                                }
                                            }
                                        },
                                        x: {
                                            grid: {
                                                display: false,
                                            },
                                            ticks: {
                                                font: {
                                                    family: 'Inter'
                                                }
                                            }
                                        }
                                    },
                                    elements: {
                                        point:{
                                            radius: 0
                                        }
                                    }
                                }}
                            />
                        </Box>
                        
                    </Box>
                </>
            )
        }
        </>
    )
}