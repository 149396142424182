import { Box, Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';
import {  useState } from 'react';
import { SnakeLoader } from '../../../../../core/loader/SnakeLoader'
import { rqx } from '../../../../../core/request/API';
import { env } from '../../../../../core/Env';
export const Submit = ({exam, urlParam, questions, status, setExam, __SESSION, timer, setTimer, setResult, setStatus}) => {
    const [loader, setLoader] = useState(false);
    const handleSubmit = async() => {
        setLoader(true);
        let score = questions.filter(q => q.u_result === 1)
        let percentage = score.length/questions.length*100;
        let exam_answer = [];
        for (let i = 0; i < questions.length; i++) {
            exam_answer = exam_answer.concat({question_id: questions[i].id, category_type: questions[i].u_category, answer: questions[i].u_answer, status: questions[i].u_result})
        }
        let category = [1,2,3]
        let sed = []
        for (let k = 0; k < category.length; k++) {
            let total = exam_answer.filter(f => f.category_type === category[k])
            let correct = total.filter(f => f.status === 1)
            sed.push({total: total.length, correct: correct.length})
        }
        let post = await rqx.post(`<<tool>>/${env()}/examActivity/create`, {aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), topic_id: urlParam.get('tid'), set_id: urlParam.get('setId'), take: urlParam.get('take'), score: score.length, status: (percentage >=75 ? 1 : 2), time_spent: timer.at[0]+':'+timer.at[1]+':'+timer.at[2], answer: exam_answer, from: 'sls', created_at: null, uaid: null})
        if(post.msg === 'success'){
            setResult({score: score.length, avg: Math.round(percentage), time_spent: [timer.at[0],timer.at[1],timer.at[2]], status: (percentage >=75 ? 1 : 2), sed: sed, etid: post.etid, esid: post.esid, eaid: post.eaid})
            fetchActivities()
        }
    }
    const fetchActivities = async() => {
        let fetch = await rqx.get(`<<tool>>/${env()}/examActivity/read`, {set_id: urlParam.get('setId'), esid: urlParam.get('esid'), take: urlParam.get('take') + 1, aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1])})
        if(fetch.msg === 'success'){
            setStatus({esstatus: fetch.esstatus, rq: fetch.rq})
            if(fetch.rq > 0){
                setExam({...exam, status: 'result'})
            }else{
                setExam({...exam, status: 'success'})
            }
        }
    }
    return (
        <Dialog open={exam.isDialog} fullWidth maxWidth={'xs'} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    <Box mt="20px" color="#000" fontSize="16px" textAlign="center">Are you sure you want to submit?</Box>
                </DialogContent>
                <DialogActions sx={{display: 'block', width: '100%', textAlign: 'right', pb: '24px', pr: '24px'}}>
                    <Button variant="outlined" onClick={()=>setExam({...exam, isDialog: false, status: null})} disabled={loader ? true : false}>Cancel</Button>
                    <Button variant="contained" onClick={handleSubmit} disabled={loader ? true : false}>
                        {
                            loader ? (
                                <Box height="25px" display="flex" alignItems="center" justifyContent="center"><SnakeLoader size="10px" bg="#fff" distance="0.3rem" /></Box>
                            ) : (
                                'Submit'
                            )
                        }
                    </Button>
                </DialogActions>
        </Dialog>
    )
}