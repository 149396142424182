import { Chart as ChartJS, ArcElement, Tooltip, Legend, DoughnutController } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
class Custom  extends DoughnutController {
    draw() {
        super.draw(arguments);
        // Now we can do some custom drawing for this dataset. Here we'll draw a red box around the first point in each dataset
        const chart = this.chart.chart;
        const ctx = this.chart.ctx;
        const width = chart.width;
        const height = chart.height;

        const fontSize = (height / 100).toFixed(2);
        ctx.font = fontSize + "em";
        ctx.textBaseline = "middle";
        var text = chart.config.data.text, textX = Math.round((width - ctx.measureText(text).width) / 2),textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
    }
}
Custom.id = 'Doughnut';
Custom.defaults = DoughnutController.defaults;
ChartJS.register(ArcElement, Tooltip, Legend, Custom);
export const Graph = ({percentage}) => {
    return (
        <Doughnut
        data={{
            labels: ['Grade', 'Variance'],
            datasets: [
              {
                label: '# of Votes',
                data: [percentage, (100 - percentage)],
                backgroundColor: (percentage >= 75 ? ['#2EAE84', '#dee9f1'] : ['#fa3e3e', '#e6e4ef']),
                borderColor: ['#9e9e9e66', '#9e9e9e66'],
                borderWidth: 0
              },
            ],
        }}
        options={{
            responsive: true, 
            plugins: {
                legend: {
                    display: false,
                },
            },
            cutout: 30
        }}
        plugins={[
            {
              beforeDraw(chart) {
               const { width } = chart;
               const { height } = chart;
               const { ctx } = chart;
               ctx.restore();
               ctx.font = `bold 17px sans-serif`;
               ctx.textBaseline = 'middle';
               const text = percentage+'%';
               const textX = Math.round((width - ctx.measureText(text).width) / 2);
               const textY = height / 2;
               ctx.fillStyle = percentage > 74 ? '#2EAE84' : 'red';
               ctx.fillText(text, textX, textY);
               ctx.save();
             },
           },
         ]}
        />
    )
}