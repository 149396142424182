import { Box } from '@mui/material';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ToolContext } from '../../../core/context/ToolContext';
import { Index as Topics } from './topics/Index'
import { Index as Sets } from './sets/Index'
import { Index as Questionnaire } from './questionnaire/Index'
import { Index as Results } from './results/Index'
import { Subjects } from './Subjects'
export const TakeExam = () => {
    const urlParam = new URLSearchParams(useLocation().search);
    const { tool_state } = useContext(ToolContext)
    const __SESSION = tool_state.__SESSION.data
    return (
        <Box height="100%"  pt="64px" bgcolor="#fff">
            {
                urlParam.get('page') === 'topics' ? (
                    <Topics urlParam={urlParam} __SESSION={__SESSION}/>
                ): urlParam.get('page') === 'sets' ? (
                    <Sets urlParam={urlParam} __SESSION={__SESSION}/>
                ) : urlParam.get('page') === 'questionnaire' ? (
                    <Questionnaire urlParam={urlParam} __SESSION={__SESSION}/>
                ) : urlParam.get('page') === 'results' ? (
                    <Results urlParam={urlParam} __SESSION={__SESSION}/>
                ) : (
                    <Subjects __SESSION={__SESSION}/>
                )
            }
        </Box>
    )
}