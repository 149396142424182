import { lazy } from 'react';
import { Box, Container } from '@mui/material';
import { Route, Switch, useLocation } from 'react-router-dom';
import { VM } from '../VM';
import { Switching } from '../../__personal/components/switching/Index'
import { Index as ToolCategories } from '../../__personal/components/toolcategories/Index';
import { RenderAPI } from '../../__personal/components/global/RenderAPI';
import { Support } from '../../__personal/components/profile/Support';
let view = VM();
const Home = lazy(() => import(`../../__personal/components/home${view}/Index`))
const Navigation = lazy(() => import(`../../__personal/components/layouts/navigations${view}/Index`))
const Profiling = lazy(() => import(`../../__personal/components/personalization${view}/profiling/Index`))
const TakeExam = lazy(() => import(`../../__personal/components/takeExam${view}/Index`))
const Leaderboard = lazy(() => import(`../../__personal/components/leaderboard${view}/Index`))
const Tutorial = lazy(() => import(`../../__personal/components/takeExam${view}/tutorial/Index`))
const Profile = lazy(() => import(`../../__personal/components/profile${view}/Index`))

export const WebRoutev1 = () => {
    const location = useLocation().pathname;
    return (
        <Switch>
            <Route exact path="/psl" render={props=>(Render(Home, props, location))} />
            <Route exact path="/psl/switching/:params" render={props=><Switching {...props}/>} />
            <Route exact path="/psl/profiling" render={props=>(Render(Profiling, props, location))} />
            <Route exact path="/psl/profile" render={props=>(Render(Profile, props, location))} />
            <Route exact path="/psl/profile/support" render={props=>(Render(Support, props))} />
            <Route exact path="/psl/take-exam" render={props=>(Render(TakeExam, props, location))} />
            <Route exact path="/psl/take-exam/tutorial" render={props=>(Render(Tutorial, props, location))} />
            <Route exact path="/psl/leaderboard" render={props=>(Render(Leaderboard, props, location))} />
            <Route exact path="/psl/tool-categories/:params" render={props=>(Render(ToolCategories, props, location))} />
            <Route>
                <Container>
                    <Box display="flex">
                        Error 404
                    </Box>
                </Container>
            </Route>
        </Switch>
    )
}
const Render = (Component, props, location) => {
    return (
        <Box width="100%" height="100%">
            {
                view === '' ? (
                    <Box display="flex" width="100%" height="100%">
                        <Navigation side={0} />
                        <Box display="flex" width="100%" height="100%">
                            <Navigation side={1} />
                            <Box width="100%" height="100%">
                                <Component {...props}/>
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <Box width="100%" height="100%">
                        <Box width="100%" height="100%" display="flex" flexDirection="column">
                            {
                                (location !== '/profile' && location !== '/take-exam/tutorial') && <Navigation side={0} />
                            }
                            <Box height="100%" width="100%"><Component {...props} /></Box>
                        </Box>
                        {
                            location !== '/profiling' &&
                            <Box minHeight="54px">
                                <Navigation side={2} />
                            </Box>
                        }
                    </Box>
                )
            }
            <RenderAPI/>
        </Box>
    )
}