import { ToolStates } from './ToolStates';
import { NavigationStates } from './NavigationStates';
import { HomeStates } from './HomeStates';
import { ToolCategoryState } from './ToolCategoryState';
export const Contexts = () => {
    return {
        tool_state: ToolStates(),
        navigation_state: NavigationStates(),
        tool_category_state: ToolCategoryState(),
        home_state: HomeStates(),
    }
}