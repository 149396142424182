import { Autocomplete, Box, Drawer, IconButton, InputLabel, TextField, MenuItem, Button } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Close } from '../../../../../core/global/Icons';
import { env } from '../../../../../core/Env';
import { rqx } from '../../../../../core/request/API';
import { SnakeLoader } from '../../../../../core/loader/SnakeLoader'
import { useState } from 'react';
import moment from 'moment';
let hasValue = {};
export const Setting = ({graph, setting, setSetting, __SESSION, setGraph, users}) => {
    const types = [{id: 0, value: 'Licensures'}, {id: 1, value: 'Certifications'}]
    const [input, setInput] = useState({type: '', exam: '', month: new Date(), day: new Date(), year: new Date(), sequence: ''})
    const [exams, setExams] = useState([]);
    const sequences = [{label: '6 Succeeding days', value: 0},{label: '6 Previous days', value: 1}]
    const [disabled, setDisabled] = useState(true)
    const [loading, setLoading] = useState(false);
    const handleChange = async(name, value) => {
        if(name === 'type'){
            if(value === 0){
                let data = await rqx.get(`<<tool>>/${env()}/licensure/read`)
                setExams(data);
            }else{
                setExams([]);
                setDisabled(true)
            }
        }
        
        handleValidation(name, value);
        setInput({...input, [name]:value})
        
    }
    const handleValidation = (name, value) => {
        hasValue[name] = value
        if(Object.keys(hasValue).length === 3){
            setDisabled(false)
        }
    }
    const handleGenerate = async() => {
        setLoading(true)
        let date = input.year.getUTCFullYear()+'-'+(input.month.getMonth() + 1)+'-'+input.day.getUTCDate()
        let start = input.sequence === 0 ? moment(date).format('Y-MM-DD') : moment(date).subtract(5, 'days').format('Y-MM-DD');
        let end = input.sequence === 0 ? moment(date).add(5, 'days').format('Y-MM-DD') : moment(date).format('Y-MM-DD');
        const getData = await rqx.get(`<<tool>>/${env() === 'local' ? 'dev' : env()}/user/getDAU`, {company_id: __SESSION.uaccess[0].company_id, tool_id: process.env.REACT_APP_TID_BIZ, platform: 'biz', start: start, end: end})
        if(getData.msg === 'success'){
            let labels = [];
            let a = [];
            let na = []
            let ds =  moment(date).format('Y-MM-DD');
            for (let i = 0; i < 6; i++) {
                let s =  moment(ds).add(i, 'days').format('Y-MM-DD');
                if(getData.data.length > 0){
                    let filter = getData.data.filter(f => f.date === s)
                    a.push(filter.length > 0 ? filter[0].total : 0)
                    na.push(filter.length > 0 ? (1-filter[0].total): users)
                }else{
                    a.push(0)
                    na.push(users)
                }
                labels.push(moment(s).format('MMM D, YYYY'))
            }
            setGraph({...graph, status: 'has-data', label: labels, active: a, not_active: na})
            setSetting({open: false})
        }
    }
    return (
        <Drawer anchor={'right'} open={setting.open} sx={{"& .MuiDrawer-paper": {overflowY: "visible", mt: "0px"}, transition: 'width 0.3s ease 0s', zIndex: 1202}}>
            <Box position="absolute" left="-60px" width="44px" height="44px" bgcolor="#fff" display="flex" justifyContent="center" alignItems="center" borderRadius="50px"  sx={{top: '10px'}}>
                <IconButton onClick={()=>setSetting({open: false})}>
                    <Close />
                </IconButton>
            </Box>
            <Box width="340px" mt={"0px"} p="10px">
                <Box sx={{'& .MuiTextField-root': { mr: 1, width: '100%' }}} mb="10px">
                    <InputLabel sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>Type</InputLabel>
                    <TextField id="outlined-select-type" name="type" value={input.type} select onChange={(e)=>handleChange('type', e.target.value)}>
                        {
                            types.map((t,i) => (
                                <MenuItem key={i} value={t.id}>{t.value}</MenuItem>
                            ))
                        }
                    </TextField>
                </Box>
                <Box mb="10px">
                    <InputLabel sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>Name of Exam</InputLabel>
                    <Autocomplete 
                        defaultValue={{ name: input.exam }}
                        options={exams}
                        getOptionLabel={(option) =>  option.name}
                        onChange={(e, value) => handleChange('name', value)} 
                        renderInput={(params) => <TextField {...params} />} 
                    />
                </Box>
                <Box mb="20px">
                    <Box sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>Date From:</Box>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Box display="flex" width="100%">
                            <Box mr={1}>
                                <InputLabel sx={{fontSize: '14px', color: '#333333'}}>Month</InputLabel>
                                <DatePicker  openTo="month" views={['month']} inputFormat="MMMM" value={input.month} onChange={(e)=>{setInput({...input, month: e, day: e})}} renderInput={(params) => <TextField {...params}  margin="dense"/>} disableMaskedInput />
                            </Box>
                            <Box mr={1}>
                                <InputLabel sx={{fontSize: '14px', color: '#333333'}}>Day</InputLabel>
                                <DatePicker openTo="day" views={['day']} inputFormat="d" value={input.day} onChange={(e)=>{setInput({...input, day: e})}} renderInput={(params) => <TextField {...params} margin="dense"/>} />
                            </Box>
                            <Box width="100%">
                                <InputLabel sx={{fontSize: '14px', color: '#333333'}}>Year</InputLabel>
                                <DatePicker openTo="year" views={['year']} inputFormat="Y" value={input.year} onChange={(e)=>{setInput({...input, year: e})}} renderInput={(params) => <TextField {...params} margin="dense" fullWidth/>} />
                            </Box>
                        </Box>
                    </LocalizationProvider>
                </Box>
                <Box mb="20px">
                    <InputLabel sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>Sequence</InputLabel>
                    <TextField id="outlined-select-currency" select fullWidth value={input.sequence} onChange={(e)=>handleChange('sequence', e.target.value)}>
                        {
                            sequences.map((option) => (
                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))
                        }
                    </TextField>
                </Box>
                {
                    loading ? (
                        <Box bgcolor="#11783C" borderRadius="6px" p="6px">
                            <Box height="25px" display="flex" alignItems="center" justifyContent="center"><SnakeLoader size="10px" bg="#fff" distance="0.3rem" /></Box>
                        </Box>
                    ) : (
                        <Button variant="contained" sx={{borderRadius: '6px'}} fullWidth disabled={input.type === 0 ? disabled : true} onClick={handleGenerate}>Generate</Button>
                    )
                }
            </Box>
        </Drawer>
    )
}