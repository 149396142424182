import { useState } from "react"
import { Home1, POFChatLogo, Notification1, Report, TakeExam1, TakeExam2, Leaderboard1, Leaderboard2 } from '../../global/Icons';
export const NavigationStates = () => {
    const [tabs, setTabs] = useState({
        top: [
            {name:'Chat', subdir:'/', ref: '__chat', not_active:<POFChatLogo size="24px" br="100%"/>, active:<POFChatLogo size="24px" br="100%"/>},
            {name:'Notifications', subdir:'/', ref: '/__notif', not_active:<Notification1 fill="#283745"/>, active:<Notification1 fill="#52616B"/>},
        ],
        left: {
            superAdmin: [
                    {name:'Dashboard', subdir:'/biz', ref: '/biz', tour: 'first-step', not_active:<Home1 stroke="#107038"/>, active:<Home1 fill="#107038" stroke="#E8E8E8"/>},
                    {name:'Reports', subdir:'/biz/reports', ref: '/biz/reports', tour:'second-step',not_active:<Report stroke="#89A594"/>, active:<Report  stroke="#107038"/>},
                ],
            regularUser: [
                    {name:'Dashboard', subdir:'/biz', ref: '/biz', tour: 'first-step', not_active:<Home1 stroke="#107038"/>, active:<Home1 fill="#107038" stroke="#E8E8E8"/>},
                    {name:'Take Exam', subdir:'/biz/take-exam', ref: '/biz/take-exam', tour:'second-step',not_active:<TakeExam1 stroke="#89A594"/>, active:<TakeExam2 fill="#107038" stroke="#E8E8E8"/>},
                    {name:'Leaderboard', subdir:'/biz/leaderboard', ref: '/biz/leaderboard', tour:'third-step', not_active:<Leaderboard1 stroke="#89A594" />, active:<Leaderboard2 fill="#107038" stroke="#E8E8E8"/>},
                ]
            },
        bottom: []
    })
    const [myTool, setMyTool] = useState(false)
    const [leftBar, setLeftbar] = useState(true)
    return {
        tabs: {data: tabs, set: setTabs},
        myTool: {data: myTool, set: setMyTool},
        leftBar: {data: leftBar, set: setLeftbar},
    }
}