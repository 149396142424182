// Libraries
import React, { Suspense } from 'react';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import { ToolProvider } from '../core/context/ToolContext';

// Assets
import '../assets/css/global.css';

// Layouts
import { Core as AuthCore } from './Core';
import { Box } from '@mui/system';
import { Logo } from '../core/global/Icons';
import { SnakeLoader } from '../core/loader/SnakeLoader';

export const Index = () => {
    const pageTheme = createTheme({
		palette: {
			background: {
				default: '#fff'
			},
			primary: {
				main: '#256EFF',
				dark: '#3A6CDE',
				light: '#e9ebee',
				lighter: '#f8f9fa',
			},
			secondary: {
				main: '#FFF',
				dark: '#E0E9FD',
				light: '#ffffff',
			},
			info: {
				main: '#00246e',
				dark: '#1a4295',
				light: '#1a4295',
			},
			error: {
				main: '#f44336',
				light: '#f6685e',
				dark: '#d32f2f'
			},
			warning: {
				main: '#ff9800',
				dark: '#b26a00',
				light: '#ffac33'
			},
			success: {
				main: '#4caf50',
				dark: '#388e3c',
				light: '#81c784'
			},
			btnLight: {
				main: '#dae5fe',
				dark: '#E0E9FD',
				contrastText: "#4A81FD"
			},
		},
		typography: {
			button: {
				textTransform: 'none',
				fontWeight: 'regular'
			},
			fontFamily: [
			  '-apple-system',
			  '"Inter"',
			].join(','),
		},
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: '8px',
						fontSize: '14px',
						boxShadow: 'none',
						"&:hover" : {
							boxShadow: 'none',
						},
						"&:disabled": {
							backgroundColor: '#C7DAFF',
							color: '#fff'
						}
					},
					outlined: {
						borderColor: "#CCE0FF",
						color: '#256EFF',
						background: '#fff',
						"&:disabled": {
							backgroundColor: '#fff',
							borderColor: "#E5EBF6",
							color: '#A2A3A9'
						},
						"&:hover" : {
							borderColor: "#CCE0FF",
							background: '#fff',
						},
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderRadius: '8px',
						borderColor: '#C6CEDE',
						"&:hover" : {
							borderColor: '#C6CEDE',
						}
					}
				},
			},
			MuiSnackbar: {
				styleOverrides: {
					root: {
						zIndex: 1500
					}
				}
			}
		}
	});

	let Core
	Core = AuthCore

    return (
        <ThemeProvider theme= { pageTheme }>
			<CssBaseline />
			<ToolProvider platform="personal">
				<Suspense fallback={
					<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
						<Logo size={60}/>
						<Box mt={4}>
							<SnakeLoader size="1rem" bg="#0070EF" distance="0.3rem" />
						</Box>
					</Box>
				}>
					<Core />
				</Suspense>
			</ToolProvider>
		</ThemeProvider>
    )
}