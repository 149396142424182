import { useState } from "react"

export const HomeStates = () => {
    const [myReasons, setMyReasons] = useState({isLoading: true, reasons: null, image: null})
    const [myPlan, setMyPlan] = useState({isLoading: true, plan: null, progress_level: null})
    const [pslztn, setPslztn] = useState(null)
    return {
        myReasons: {data: myReasons, set: setMyReasons},
        myPlan: {data: myPlan, set: setMyPlan},
        pslztn: {data: pslztn, set: setPslztn}
    }
}