import { Box, IconButton, Skeleton, Grid } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Settings1 } from '../../../../../core/global/Icons';
ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export const SED = ({sedProgress, setSettings}) => {
    return (
        <Box borderRadius="6px" border="1px solid #E5EBF6" p="24px" mt="40px" bgcolor="#fff">
            {
                sedProgress.stat === 'loading' ? (
                    <>
                    <Box display="flex" justifyContent="space-between" mb="24px">
                        <Skeleton variant="text" width={100} sx={{ fontSize: '14px' }} />
                        <Skeleton variant="rounded" width={40} height={40} sx={{borderRadius: '100px'}}/>
                    </Box>
                    <Box display="flex">
                        <Skeleton variant="rounded" width="60%" height={300} sx={{borderRadius: '12px'}}/>
                        <Box width="40%" ml="20px">
                            <Skeleton variant="rounded" height={300} sx={{borderRadius: '12px'}}/>
                        </Box>
                    </Box>
                    </>
                ) : (
                    <>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb="14px">
                        <Box fontSize="14px" fontWeight={600}>SED Progress</Box>
                        <IconButton sx={{width: '40px', height: '40px'}} onClick={()=>setSettings({open: true, for: 'SED'})} disabled={sedProgress.stat === 'has-exam' ? false : true}>
                            <Settings1 />
                        </IconButton >
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={7}>
                            <Box textAlign="center" fontSize="12px" fontWeight={400}>Daily</Box>
                            <Box display="flex" justifyContent="center" position="relative"  sx={{height: '345px'}} mb="24px">
                                {
                                    sedProgress.stat === 'no-exam' &&
                                    <Box bgcolor="#f0f6f3d1" position='absolute' height="275px" display="flex" sx={{width: {'lg': '89%', 'md': '90%'}, ml: '65px', mt: '21px'}} justifyContent="center" alignItems="center" borderRadius="12px" >
                                        <Box color="#313131" fontWeight={600}>No Exam taken yet.</Box>
                                    </Box>
                                }
                                <Bar 
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        plugins: {
                                            legend: false,
                                            tooltip: {
                                                callbacks: {
                                                    title: function(t){},
                                                    label: function(v){
                                                        if(sedProgress.data.length === 1){
                                                            let d = sedProgress.data[0].label+': ' + sedProgress.data[0].data[v.dataIndex] + '%';
                                                            return [d];
                                                        }else{
                                                            let sure = 'Sure: ' + sedProgress.data[0].data[v.dataIndex] + '%';
                                                            let easy = 'Easy: ' + sedProgress.data[1].data[v.dataIndex] + '%';
                                                            let difficult = 'Difficult: ' + sedProgress.data[2].data[v.dataIndex] + '%';
                                                            return [sure, easy, difficult];
                                                        }
                                                    },
                                                },
                                                displayColors: false,
                                            }
                                        },
                                        categoryPercentage: 0.8,
                                        barPercentage: 0.7,
                                        scales: {
                                            y: {
                                                min: 0,
                                                max: 100,
                                                grid: {
                                                    drawBorder: false,
                                                    borderDash: [8, 4],
                                                    color: '#B5C0D7'
                                                },
                                                ticks: {
                                                    callback: function(value) {
                                                        return value + '%';
                                                    },
                                                    padding: 20,
                                                    color: '#000',
                                                    stepSize: 20,
                                                    font: {
                                                        family: 'Inter'
                                                    }
                                                }
                                            },
                                            x: {
                                                grid: {
                                                    display: false,
                                                }, 
                                                position: 'bottom',
                                                ticks: {
                                                    font: {
                                                        family: 'Inter'
                                                    }
                                                }
                                            },
                                            x2: {
                                                labels: sedProgress.label2.length > 0 ? sedProgress.label2 : ['----', '----', '----', '----', '----', '----'],
                                                grid: {
                                                    drawBorder: false,
                                                    display: false
                                                },
                                                ticks: {
                                                    font: {
                                                        family: 'Inter'
                                                    }
                                                }
                                            }
                                            }
                                        }}
                                        data={{
                                            labels : sedProgress.label1.length > 0 ? sedProgress.label1 : ['--', '--', '--', '--', '--', '--'], 
                                            datasets: sedProgress.data.length > 0 ? sedProgress.data : [{label: 'Sure',data: ['50', '50', '50', '50', '50', '50'],backgroundColor: '#18A677', borderRadius: 5,borderSkipped: false,},{label: 'Easy',data: ['80', '80', '80', '80', '80', '80'],backgroundColor: '#4A81FD',borderRadius: 5,borderSkipped: false,},{label: 'Difficult',data: ['40', '40', '40', '40', '40', '40'],backgroundColor: '#FF5B5B',borderRadius: 5,borderSkipped: false,}]
                                        }}
                                />
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <Box display="flex">
                                    <Box display="flex" alignItems="center" mr="24px">
                                        <Box width="12px" height="12px" bgcolor="#18A677" borderRadius="100%" mr="8px"></Box>
                                        <Box fontSize="12px" fontWeight={400}>Sure</Box>
                                    </Box>
                                    <Box display="flex" alignItems="center" mr="24px">
                                        <Box width="12px" height="12px" bgcolor="#4A81FD" borderRadius="100%" mr="8px"></Box>
                                        <Box fontSize="12px" fontWeight={400}>Easy</Box>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <Box width="12px" height="12px" bgcolor="#FF5B5B" borderRadius="100%" mr="8px"></Box>
                                        <Box fontSize="12px" fontWeight={400}>Difficult</Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={5}>
                            <Box bgcolor="#f0f6f3" borderRadius="12px" p="16px" ml="20px" mt="50px">
                                <Box fontSize="14px" fontWeight={600}>Subject:</Box>
                                <Box  mb="12px" fontSize="14px">{sedProgress.stat === 'no-exam' ? '--' : sedProgress.summary.subject}</Box>
                                <Box fontSize="14px" fontWeight={600}>Topic:</Box>
                                <Box  mb="12px" fontSize="14px">{sedProgress.stat === 'no-exam' ? '--' : sedProgress.summary.topic}</Box>
                                <Box fontSize="14px" fontWeight={600}>Takes:</Box>
                                <Box  mb="12px" fontSize="14px">{sedProgress.stat === 'no-exam' ? '--' : sedProgress.summary.take}</Box>
                                <Box fontSize="14px" fontWeight={600}>Technique:</Box>
                                <Box  mb="12px" fontSize="14px">{sedProgress.stat === 'no-exam' ? '--' : sedProgress.summary.technique}</Box>
                            </Box>
                        </Grid>
                    </Grid> 
                    </>
                )
            }
        </Box>
    )
}