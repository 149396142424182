import { Box, Skeleton, IconButton, Grid } from '@mui/material';
import { Settings1 } from '../../../../../core/global/Icons';
import { Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend, } from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend);
export const PRSP = ({prsp, setSettings}) => {
    return (
        prsp.stat === 'loading' ? (
            <Box bgcolor="#fff" borderRadius="12px" padding="20px" mb="32px" minHeight="600px">
                <Box display="flex" justifyContent="space-between">
                    <Box display="flex" flexDirection="column">
                        <Skeleton variant="text" width={130} sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" width={50} sx={{ fontSize: '15px' }} />
                    </Box>
                    <Skeleton variant="rounded" width={40} height={40} sx={{borderRadius: '12px'}}/>
                </Box>
                <Skeleton variant="rounded" width="100%" height={250} sx={{borderRadius: '12px', mt: '32px'}}/>
                <Box mt={2}>
                    <Grid container spacing={2}>
                        {
                            [0,1,2,3,4,5].map(k => (
                                <Grid key={k} item xs={12} md={6} lg={6}>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Box display="flex" alignItems="center">
                                            <Skeleton variant="circular" width={15} height={15} sx={{mr:'10px'}}/>
                                            <Skeleton variant="text" width={50} sx={{ fontSize: '1rem', mr: '10px' }} />
                                        </Box>
                                        <Skeleton variant="text" width={20} sx={{ fontSize: '1rem', mr: '10px' }} />
                                    </Box>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </Box>
        ) : (
            <Box bgcolor="#fff" borderRadius="12px" mb="32px" >
                <Box display="flex" justifyContent="flex-end" mb="10px">
                    <IconButton sx={{width: '40px', height: '40px'}} onClick={()=>setSettings({open: true, for: 'PRSP'})} disabled={prsp.stat === 'no-exam' ? true : false}>
                        <Settings1 fill="#525B6D" />
                    </IconButton >
                </Box>
                <Box display="flex" justifyContent="center" position="relative">
                    {
                        prsp.stat === 'no-exam' && 
                        <Box bgcolor="#f0f6f3d1" position='absolute' height="275px" display="flex" justifyContent="center" alignItems="center" borderRadius="12px" sx={{width: {'lg': '95%', 'md': '90%'}, ml: '65px', mt: '20px'}}>
                            <Box textAlign="center">No Exam taken yet.</Box>
                        </Box>
                    }
                    <Box  height="300px" width="100%">
                        <Box fontSize="12px" textAlign="center">{prsp.interval}</Box>
                        <Line 
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    title: {
                                        display: false,
                                    },
                                },
                                scales: {
                                    y: {
                                        min: 0,
                                        max: 100,
                                        grid: {
                                            drawBorder: false,
                                            borderDash: [8, 4],
                                            color: '#B5C0D7'
                                        },
                                        ticks: {
                                            callback: function(value) {
                                                return value + '%';
                                            },
                                            padding: 20,
                                            stepSize: 20,
                                            color: '#000',
                                            font: {
                                                family: 'Inter'
                                            }
                                        }
                                    },
                                    x: {
                                        grid: {
                                            display: false,
                                        },
                                    }
                                },
                                elements: {
                                    point:{
                                        radius: 0
                                    }
                                }
                            }}
                            data={{
                                labels : prsp.label,
                                datasets: prsp.data
                            }}
                        />
                    </Box>
                </Box>
                <Box mt="40px">
                    <Grid container spacing={2}>
                        {
                            prsp.data.map((subject, i) => (
                                <Grid key={i} item xs={12} md={6} lg={6}>
                                    <Box display="flex" alignItems="center" justifyContent="space-between" border="1px solid #E5EBF6" borderRadius="4px" p="4px">
                                        <Box display="flex" alignItems="center">
                                            <Box bgcolor={subject.backgroundColor} height={'11px'} width={'11px'} borderRadius="50%" mr="5px"></Box>{subject.label}
                                        </Box>
                                        <Box fontSize="14px" color="#313131" fontWeight={600}>{Math.round(subject.data[3])}%</Box>
                                    </Box>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </Box>
        )
    )
}