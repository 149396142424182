import { Box, Button, Divider, Grid, IconButton } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles'
import { Index as ProgressLevel } from './progressLevel/Index'
import { ToolContext } from '../../../../core/context/ToolContext';
import { PenEdit } from '../../../../core/global/Icons';
import { SED } from './graphs/SED'
import { AQR } from './graphs/AQR'
import { PRSP } from './graphs/PRSP'
import { GrowthRate } from './graphs/GrowthRate'
import { DBTE } from './my/DBTE';
import { Reasons } from './my/Reasons';
import { Index as Leaderboards } from './leaderboards/Index'
import { SED as SEDSettings } from './utils/drawers/SED'
import { Settings } from './utils/drawers/Settings'
import { rqx } from '../../../../core/request/API';
import { env } from '../../../../core/Env';
import { fetchSEDProgress, fetchAQReport, fetchGrowthRate, fetchMyData, fetchProgressReport } from './Functions';
const useStyles = makeStyles({
    active_button: {
        background: '#fff',
        color: '#000',
        borderRadius: '6px',
        boxShadow: '0px 0px 4px 0px #0000001F',
        '&:hover': {
            background: '#fff'
        }
    },
    not_active_button: {
        color: '#000'
    }
});
export const Index = () => {
    const classes = useStyles();
    const { tool_state, home_state } = useContext(ToolContext)
    const myPlan = home_state.myPlan
    const myReasons = home_state.myReasons
    const __SESSION = tool_state.__SESSION.data
    const pslztn = home_state.pslztn
    const [graph, setGraph] = useState('MLP');
    const [settings, setSettings] = useState({open: false, for: ''});
    const [sedProgress, setSEDProgress] = useState({stat: 'loading', summary: [], label1: [], label2: [], data: []})
    const [aqReport, setaqReport] = useState({stat: 'loading', interval: 'Daily', date: '', quarter: 1, sequence: 'succeeding', label: [], data: []});
    const [growthRate, setGrowthRate] = useState({stat: 'loading', gr: [], ptp: 0, avg: 0})
    const [prsp, setPrsp] = useState({stat: 'loading', interval: 'Daily', date: '', quarter: 1, sequence: 'succeeding', label: [], data: []});
    const fetchActivities = async() => {
        let fetch = await rqx.get(`<<tool>>/${env()}/examActivity/get`, {aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), licensure_id: __SESSION.ulicensure[0].id})
        let isStatus = null;
        if(fetch > 0){
            isStatus = 'has-exam';
            fetchSEDProgress({aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), licensure_id: __SESSION.ulicensure[0].id, from: 'dashboard', take: 'All', technique: 'All'}, {}, sedProgress, setSEDProgress, 'desktop');
            fetchGrowthRate(parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), __SESSION.ulicensure[0].id, setGrowthRate);
        }else{
            setSEDProgress({...sedProgress, stat: 'no-exam'})
            setaqReport({...aqReport, stat: 'no-exam'})
            setGrowthRate({...growthRate, stat: 'no-exam'})
        }
        if(isStatus !== null){
            fetchAQReport({aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), from: 'dashboard', label: 'Daily', interval: 'days', sequence: 'succeeding', date: '', quarter: 1, status: isStatus}, aqReport, setaqReport);
            fetchProgressReport({aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), from: 'dashboard', label: 'Daily', interval: 'days', sequence: 'succeeding', date: '', quarter: 1, licensure_id: __SESSION.ulicensure[0].id, status: isStatus}, setPrsp, 'desktop');
        }
    }
    useEffect(() => {
        if(__SESSION.pinfo.length > 0){
            fetchActivities();
            fetchMyData(parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), myPlan, myReasons)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Box height="100%" width="100%" mt="64px">
            <Grid container>
                <Grid item xs={12} md={10} lg={9}>
                    <Box bgcolor="#fff" borderRight="1px solid #E5EBF6" p="41px 56px">
                        <Box fontWeight={700} fontSize="24px" lineHeight="38px" mb="6px">Dashboard (User)</Box>
                        <Box fontSize="14px" fontWeight={400} color="#313131" mb="15px">Extraordinary & Heroic Day Hero Users!</Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box>
                                <Box fontSize="14px" fontWeight={600} mb="6px">My Progress Level</Box>
                                <Box fontSize="12px" fontWeight={400}>Every level has a different set of questions to complete. It will be added to the next level if you haven't finished all of them.</Box>
                            </Box>
                            <IconButton onClick={()=>pslztn.set('change-wtk')}><PenEdit /></IconButton>
                        </Box>
                        <ProgressLevel loading={myPlan.data.isLoading} progress={myPlan.data.progress_level}/>
                        <SED sedProgress={sedProgress} setSettings={setSettings}/>
                        <Box borderRadius="6px" border="1px solid #E5EBF6" p="24px" mt="40px" bgcolor="#fff">
                            <AQR aqReport={aqReport}  setSettings={setSettings}/>
                            <Divider sx={{mt: '40px', mb: '40px', borderColor: '#E5EBF6'}}/>
                            <Box display="flex" borderRadius="6px" bgcolor="#e2ede7" height="42px" width="450px" p="4px" mb="24px">
                                <Box component={Button} px={4} width="50%" className={graph === 'MLP' ? classes.active_button : classes.not_active_button} onClick={()=>setGraph('MLP')}>My Learning Progress</Box>
                                <Box component={Button} px={4} width="50%" className={graph === 'PR' ? classes.active_button : classes.not_active_button} onClick={()=>setGraph('PR')}>Progress Report</Box>
                            </Box>
                            {
                                graph === 'PR' ? (
                                    <PRSP prsp={prsp} settings={settings} setSettings={setSettings}/>
                                ) : (
                                    <GrowthRate growthRate={growthRate}/>
                                )
                            }
                        </Box>
                        <Leaderboards __SESSION={__SESSION}/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={10} lg={3}>
                    <Box sx={{top: 72, position: 'sticky'}}>
                        <DBTE myPlan={myPlan.data}/>
                        <Reasons myReasons={myReasons.data} />
                    </Box>
                </Grid>
            </Grid>
            {
                settings.for === 'SED' &&
                <SEDSettings __SESSION={__SESSION} sedProgress={sedProgress} setSEDProgress={setSEDProgress} settings={settings} setSettings={setSettings}/>
            }
            {
                (settings.for === 'AQR' || settings.for === 'PRSP') &&
                <Settings aqReport={aqReport} setaqReport={setaqReport} __SESSION={__SESSION} settings={settings} setSettings={setSettings} setPrsp={setPrsp} prsp={prsp}/>
            }
        </Box>
    )
}