import { Box, Button, Grid, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { fetchSubjects } from './global/Functions';
export const Subjects = ({__SESSION}) => {
    const history = useHistory()
    const [loading, setLoading] = useState(true);
    const [subjects, setSubjects] = useState([]);
    const num = [20,40,60,80,100]
    useEffect(() => {
        setTimeout(() => {
            fetchSubjects(setSubjects, __SESSION, setLoading);
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
        <Box width="100%" height="100%">
            <Box p="40px 72px 24px 72px" borderBottom="1px solid #E5EBF6" sx={{boxShadow: '0px 2px 4px 0px #32464414'}}>
                <Box fontWeight={700} fontSize="24px" lineHeight="38px">Take Exam</Box>
                <Box fontSize="14px" fontWeight={400} lineHeight="22px">Here's the set of subjects that will help you pass your CPA Board Exam:</Box>
            </Box>
            <Grid sx={{p: '40px 56px'}} container spacing={2}>
                {
                    loading ? (
                        [0,1,2,3,4,5].map(k => (
                            <Grid key={k} item xs={12} md={6} lg={6}>
                                <Skeleton variant="rounded" width={'100%'} height={150} sx={{borderRadius: '10px', mb: 2}} />
                            </Grid>
                        ))
                    ) : (
                        subjects.map((s,k) => (
                            <Grid key={k} item xs={12} md={6} lg={6}>
                                <Box border={s.isDisabled !== undefined ? (s.isDisabled ? '1px solid #F6F9FF' : '1px solid #E5EBF6') : '1px solid #F6F9FF'} borderRadius="12px" p="32px" bgcolor={s.isDisabled !== undefined ? (s.isDisabled ? '#F6F9FF' : '') : '#fff'}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" mb="24px">
                                        <Box fontSize="14px" fontWeight={400} lineHeight="22px" width="70%" color={s.isDisabled !== undefined ? (s.isDisabled ? '#A2A3A9' : '') : ''}>{s.name}</Box>
                                        <Button variant="outlined" sx={{ '&:disabled':{background: '#fff', color: '#256EFF', border: '1px solid #CCE0FF'} }} onClick={() => history.push(`/biz/take-exam?page=topics&sid=${s.id}&stid=${s.subject_type_id}&sname=${s.name}`)} disabled={s.isDisabled !== undefined ? s.isDisabled : false}>View Topics</Button>
                                    </Box>
                                    <Box display="flex" mb="16px">
                                        {
                                            num.map((no, k) => (
                                                <Box key={k} bgcolor="#E5EBF6" mr="2px" height="4px" width="20%" display="flex">
                                                    <Box bgcolor={s.isDisabled !== undefined ? (s.isDisabled ? '#bac7df' : '#008F5D') : '#008F5D'} width={`${k===0 ? (Math.round(s.percentage) > no ? 100 : Math.round(s.percentage)/no*100) : (Math.round(s.percentage) > num[k-1] ? (Math.round(s.percentage)-num[k-1])/20*100 : 0)}%`} height="4px" sx={{transition: "width 2s"}}></Box>
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                    <Box display="flex" fontWeight={400} fontSize="12px" >
                                        <Box color={s.isDisabled !== undefined ? (s.isDisabled ? '#8496BB' : '#008F5D') : '#008F5D'} mr="12px">{Math.round(s.percentage)}%</Box>
                                        <Box color="#8496BB">Percentage of Answered Questions</Box>
                                    </Box>
                                </Box>
                            </Grid>
                        ))
                    )
                }
            </Grid>   
        </Box>
    )
}