import { Box, Button, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
export const All = ({num, topics, urlParam}) => {
    const history = useHistory()
    return (
        topics.map((t, k) => (
            <Grid key={k} item xs={12} md={6} lg={4}>
                <Box border="1px solid" borderColor={t.isDisabled === undefined ? '#E5EBF6' : (!t.isDisabled ? '#E5EBF6' : '#F6F9FF')} borderRadius="12px" p="32px" sx={{bgcolor: (t.isDisabled === undefined ? '#fff' : (!t.isDisabled ? '#fff' : '#F6F9FF'))}}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb="24px">
                        <Box fontSize="14px" fontWeight={400} lineHeight="22px" width="70%" height="70px" display="flex" alignItems="center">{t.name}</Box>
                        <Button variant="outlined" onClick={() => history.push(`/biz/take-exam?page=sets&sid=${urlParam.get('sid')}&stid=${urlParam.get('stid')}&sname=${urlParam.get('sname')}&tid=${t.id}&tname=${t.name}`)} disabled={t.isDisabled === undefined ? false : (!t.isDisabled ? false : true)} sx={{ '&:disabled':{background: '#fff', color: '#256EFF', border: '1px solid #CCE0FF'} }}>Take</Button>
                    </Box>
                    <Box display="flex" mb="16px">
                        {
                            num.map((no, k) => (
                                <Box key={k} bgcolor="#DAE8DA" mr="2px" height="4px" width="20%" display="flex">
                                    <Box bgcolor={t.isDisabled === undefined ? '#008F5D' : (!t.isDisabled ? '#008F5D' : '#bac7df')} width={`${k===0 ? (Math.round(t.gr) > no ? 100 : Math.round(t.gr)/no*100) : (Math.round(t.gr) > num[k-1] ? (Math.round(t.gr)-num[k-1])/20*100 : 0)}%`} height="4px" sx={{transition: "width 2s"}}></Box>
                                </Box>
                            ))
                        }
                    </Box>
                    <Box display="flex" justifyContent="space-between" fontWeight={400} fontSize="12px" >
                        <Box display="flex">
                            <Box color={t.gr > 0 ? (t.isDisabled === undefined ? '#008F5D' : (!t.isDisabled ? '#008F5D' : '#8496BB')) : '#8496BB'} mr="12px">{Math.round(t.gr)}%</Box>
                            <Box color="#8496BB">Percentage</Box>
                        </Box>
                        <Box bgcolor={t.gr >= 74 ? '#f7fcfa' : t.gr > 0 ? '#FFFDE7' : "#DAE8DA"} borderRadius="100px" color={t.gr >= 74 ? '#008F5D' : t.gr > 0 ? '#F57F17' : "#002219"} border={t.gr >= 74 ? '' : t.gr > 0 ? '1px solid #F6EDE6' : ''} fontSize="12px" fontWeight={400} p="2px 8px">{t.gr >= 74 ? 'Passed' : (t.gr > 0 ? 'In Progress' : 'Not yet Taken')}</Box>
                    </Box>
                </Box>
            </Grid>
        ))
    )
}