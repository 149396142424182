import { Box, Button, Dialog, DialogActions, DialogTitle, DialogContent, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
export const Success = ({isDialog, setIsDialog, urlParam, results}) => {
    const history = useHistory()
    return (
        <Dialog open={isDialog.open} fullWidth maxWidth={'xs'} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Congratulations!</DialogTitle>
            <DialogContent sx={{textAlign: 'center'}}>
                <Box mt="10px" mb="10px" color="#000" fontSize="16px">You have completed the topic of <Box component="span" fontWeight="bold">{urlParam.get('tname')}</Box> with {results[results.length-1].tq}/{results[results.length-1].tq} correct answers.</Box>
                <Box color="#000" fontSize="16px">Do you want to continue another set of {urlParam.get('sname')} topic?</Box>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={2} display="flex" justifyContent="center" width="100%" mb="15px">
                    <Button variant="contained" color="secondary" onClick={()=>setIsDialog({name: null, open:false})}>Not Now</Button>
                    <Button variant="contained" onClick={()=>history.push(`/take-exam?page=sets&sid=${urlParam.get('sid')}&stid=${urlParam.get('stid')}&sname=${urlParam.get('sname')}&tid=${urlParam.get('tid')}&tname=${urlParam.get('tname')}`)}>Take Now</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}