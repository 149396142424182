import { useContext } from 'react';
import { ToolContext } from '../../../core/context/ToolContext';
import { Index as SuperAdmin } from './superAdmin/Index'
import { Index as RegularUser } from './regularUser/Index'
export const Home = () => {
    const { tool_state } = useContext(ToolContext)
    const __SESSION = tool_state.__SESSION.data
    return (
        __SESSION.uaccess[0].role_name === 'Super Admin' ? (
            <SuperAdmin />
        ) : (
            <RegularUser />
        )
    )
}