import { Avatar, Box, Typography, Skeleton, Stack, Pagination } from '@mui/material'
import { useEffect, useContext, useState } from 'react';
import icon from '../../../assets/images/leaderBoard/leaderboardIcon.png'
import PSLLogo from '../../../assets/images/logos/logo_v1.png';
import { ToolContext } from '../../../core/context/ToolContext';
import { rqx } from '../../../core/request/API';
import { env } from '../../../core/Env';
import { List } from './List'
export const Leaderboard = () => {
    let header = ['Rank', 'First Name', 'Last Name', 'Email', 'School', 'Points']
    const { tool_state } = useContext(ToolContext)
    const __SESSION = tool_state.__SESSION
    const [data, setData] = useState([]);
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true);
    const [rank, setRank] = useState({no: 0, total: 0, points: 0})
    const [pages, setPages] = useState({list_num: 1, total: 10});
    const display = __SESSION.data.pinfo.length > 0 ? (__SESSION.data.pinfo[0].profile !== null ? JSON.parse(__SESSION.data.pinfo[0].profile).profile !== null ? JSON.parse(__SESSION.data.pinfo[0].profile).profile : PSLLogo : PSLLogo) : PSLLogo
    useEffect(() => {
        fetchData(0)
        fetchTotalUsers();
        /* 
        const fetchRank = async() => {
            let fetch = await rqx.get(`<<tool>>/${env()}/user/user_rank`, {'user_id': parseInt(window.atob(__SESSION.data.ainfo.aid).split('_')[1])})
            if(fetch.length > 0){
                setRank({no: fetch[0].rank, points: fetch[0].total})
            }
        }
        fetchRank(); */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const fetchData = async(skip) => {
        const fetch = await rqx.get(`<<tool>>/${env() === 'local' ? 'dev' : env()}/user/getRankings`, {company_id: __SESSION.data.uaccess[0].company_id, tool_id: process.env.REACT_APP_TID_BIZ, limit: 10, skip: skip, platform: 'biz'})
        if(fetch.msg === 'success'){
            setUsers(fetch.data)
            setLoading(false);
            if(skip === 0){
                setData(fetch.data);
            }else{
                setData(data.concat(fetch.data));
            }
        }
    }
    const fetchTotalUsers = async() => {
        let fetch = await rqx.get(`<<tool>>/${env()}/user/totalUsers`, {company_id: __SESSION.data.uaccess[0].company_id, tool_id: process.env.REACT_APP_TID_BIZ, platform: 'biz'})
        if(fetch.msg === 'success'){
            let total = fetch.data > 10 ? (fetch.data / 10) : 1;
            setPages({...pages, total: Math.round(total)})
        }
    }
    const handlePage = (event, number) => {
        let total = data.length;
        let startIndex = (number * 10) - 10
        if (total >= number * 10){
            let newLists = [];
            for (let i = 0; i < 10; i++) {
                newLists.push(data[startIndex+i])
            }
            setUsers(newLists)
        }else{
            setLoading(true)
            fetchData(total)
        }
        setPages({...pages, list_num: startIndex + 1})
    }
    return (
        <Box height="100%" width="100%" mt="54px">
            <Box sx={{background: '#165320', height: '300px'}}>
                <Box display="flex" justifyContent="center" alignItems="center" pt="100px">
                    <img src={icon} alt="icon"/>
                    <Typography variant="subtitle2" fontSize="20px" fontWeight="700" color="white" px={2}>Leaderboard</Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Box display="flex" justifyContent="space-between" pt={5} width="80%">
                            <Box  width="100%">
                                <Box display ="flex" alignItems="center" >
                                    <Avatar variant='circle' src={display} sx={{width:40 , height: 40, border: '2px solid white', mr: '10px'}} />
                                    <Typography variant="subtitle2" noWrap fontSize={16} color="white">{__SESSION.data.pinfo.length > 0 ? __SESSION.data.pinfo[0].first_name+' '+__SESSION.data.pinfo[0].last_name : 'Unnamed Hero'}</Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                        <Box display ="flex" alignItems="center" py={2}>
                                            <Typography fontSize={14} color="#fff" pr={1.5}>My Rank</Typography>
                                            <Typography fontSize={14} color="#FFFFFF">{rank.no}</Typography>
                                        </Box>
                                        <Box display ="flex" alignItems="center" py={2} pl={5}>
                                            <Typography fontSize={14} color="#fff" pr={1.5}>Points</Typography>
                                            <Typography fontSize={14} color="#FFFFFF">{rank.points}</Typography>
                                        </Box>
                                </Box>
                            </Box>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" justifyContent="center">
                <Box width="80%" height="100%" bgcolor="#FFFFFF" borderRadius="12px" p={3} mt="-50px">
                    <Box width="100%" bgcolor="#e2ede7" borderRadius="12px" padding="16px" display="flex" alignItems="center">
                            {
                                header.map((h, i) => (
                                    <Box key={i} width="100%" display="flex" justifyContent={i === 0 ? 'flex-start' : 'center'} color="#000">{h}</Box>
                                ))
                            }
                    </Box>
                    {
                            loading ? (
                                [0,1,2,3,4,5,6,7,8,9].map(k => (
                                    <Box key={k} height="83px" mt="10px" mb="10px">
                                        <Skeleton variant="rectangular" height={'100%'} sx={{borderRadius: '8px'}} />
                                    </Box>
                                ))
                            ) : (
                                users.map((d, v) => (
                                    <List key={v} d={d} v={v} pages={pages}/>
                                ))
                            )
                    }
                    <Box display="flex" justifyContent="center" pt="10px">
                        <Stack spacing={2}>
                            <Pagination count={pages.total} color="primary" onChange={(event, number)=>handlePage(event, number)} disabled={loading ? true : false}/>
                        </Stack>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}