import moment from 'moment'
import { rqx } from '../../../../core/request/API';
import { env } from '../../../../core/Env';

export const nth = (n) => {return["st","nd","rd"][((n+90)%100-10)%10-1]||"th"}

export const fetchMyData = async(aid, myPlan, myReasons) => {
    let fetch = await rqx.get(`<<tool>>/${env()}/user/read`, {aid: aid})
    if(fetch.msg === 'success'){
        if(fetch.reasons.length > 0){
            myReasons.set({...myReasons.data, reasons: fetch.reasons[0], image: (fetch.image.length === 0 ? null : fetch.image), isLoading: false})
        }else{
            myReasons.set({...myReasons.data, reasons: [], image: null, isLoading: false})
        }
        let data = [];
        if(fetch.plan.length > 0){
            let totalAQ = fetch.aq;
            let taken = totalAQ;
            let level = 0;
            for (let i = 0; i < fetch.progressLevel.length; i++) {
                data.push({
                            'level': fetch.progressLevel[i].level, 
                            'target': fetch.progressLevel[i].target_question, 
                            'taken': level === i ? totalAQ >= fetch.progressLevel[i].target_question ? fetch.progressLevel[i].target_question : taken : 0, 
                            'percentage': level === i ? totalAQ >= fetch.progressLevel[i].target_question ? 100 : Math.round(taken / fetch.progressLevel[i].target_question * 100) : 0, 
                            'date_accomplish': fetch.progressLevel[i].accomplish_date, 
                            'status': fetch.progressLevel[i].status
                        })
                if(totalAQ >= fetch.progressLevel[i].target_question){
                    taken = totalAQ - fetch.progressLevel[i].target_question;
                    level = level + 1;
                }
            }
            let end =  new Date(fetch.progressLevel[4].accomplish_date);
            let duration = moment(end).diff(new Date(),'days');
            myPlan.set({...myPlan.data, isLoading: false, plan: {id: fetch.plan[0].id, target: end, days: duration, aq: fetch.aq}, progress_level: data})
        }else{
            let targetTQ = [1800, 3600, 5400, 7200, 9000];
            for (let i = 0; i < targetTQ.length; i++) {
                data.push({
                    'level': i+1, 
                    'target': targetTQ[i], 
                    'taken': 0, 
                    'percentage': 0, 
                    'date_accomplish': null, 
                    'status': 0
                })
            }
            myPlan.set({...myPlan.data, isLoading: false, plan: null, progress_level: data})
        }
    }
}

export const calculateAVG = (avgSpeed, avg, setAvg) => {
    let time = avgSpeed*avg.tq;
    let h = Math.floor(time % (3600*24) / 3600);
    let m = Math.floor(time % 3600 / 60);
    let s = Math.floor(time % 60);
    var hrs = h > 0 ? h + (h === 1 ? " hr. " : " hrs.") : "";
    var min = m > 0 ? m + (m === 1 ? " min." : " mins.,") : "";
    var sec = s > 0 ? s + (s === 1 ? " sec." : " secs.") : "";
    setAvg({...avg, total: (hrs + (hrs === "" ? "" : " and ") + min + (min === "" ? "" : " and ") +  sec )});
}


export const fetchSEDProgress = (params, summary, sedProgress, setSEDProgress, view) => {
    setTimeout(async() => {
        let fetch = await rqx.get(`<<tool>>/${env()}/examActivity/SEDProgress`, params)
        if(fetch.msg === 'success'){
            let label1 = [];
            let label2 = [];
            let s = params.from === 'dashboard' ? fetch.summary : summary;
            let data = [{label: 'Sure', data: [], backgroundColor: '#2EAE84'}, {label: 'Easy', data: [], backgroundColor: '#5B8DFD'}, {label: 'Difficult', data: [], backgroundColor: '#FF6B6B'}];
            for (let i = 0; i < fetch.sed.length; i++) {
                label1.push((i+1) + nth(i+1) + ' Take')
                label2.push(view === 'mobile' ? moment(fetch.sed[i].created_at).format('MM-DD-YY') : moment(fetch.sed[i].created_at).format('MM-DD-YY'))
                if(params.technique === 'All'){
                    let rq = fetch.sed[0].tq - fetch.sed[i].tq;
                    data[0].data.push(Math.round((fetch.sed[i].total[0] + rq) / fetch.sed[0].tq * 100))
                    data[1].data.push(Math.round(fetch.sed[i].total[1]/fetch.sed[0].tq*100))
                    data[2].data.push(Math.round(fetch.sed[i].total[2]/fetch.sed[0].tq*100))
                }else{
                    let d = params.technique === "Sure" ? 0 : params.technique === "Easy" ? 1 : 2;
                    let rq = params.technique === "Sure" ? fetch.sed[0].tq - fetch.sed[i].tq : 0;
                    data[d].data.push(Math.round((fetch.sed[i].total[0] + rq)/fetch.sed[0].tq * 100))
                }
            }
            setSEDProgress({...sedProgress, stat: 'has-exam', hasData: true, summary: s, label1: label1, label2: label2, data: data})
        }
    }, 1000);
}

export const fetchAQReport = (params, aqReport, setaqReport) => {
    setTimeout(async() => {
        let dates = [];
        let label = [];
        let date =  params.from === 'dashboard' ? moment().subtract(3, 'days').format('Y-MM-DD') : params.date;
        for (let i = 0; i < 6; i++) {
            let start =  moment(date).add(i, params.interval).format('Y-MM-DD');
            let end = (params.interval === 'weeks' ? moment(start).add(6, 'days').format('Y-MM-DD') : moment(start).endOf(params.interval).format('Y-MM-DD'));
            dates.push({start: start, end: end});
            label.push(params.interval === 'months' ? moment(start).format('MMM YYYY') : (params.interval === 'years' ? moment(start).format('Y') : moment(start).format('MMM D, YYYY')))
        }
        let data = [];
        if(params.status === 'has-exam'){
            let start = dates[0].start;
            let end = dates[3].end;
            let fetch = await rqx.get(`<<tool>>/${env()}/examActivity/AQReport`, {aid: params.aid, start: start, end: end})
            if(fetch.length > 0 ){
                for (let i = 0; i < dates.length; i++) {
                    let filter = fetch.filter(f => f.date >= dates[i].start && f.date <= dates[i].end)
                    let answered = filter.length > 0 ? filter.reduce((f, i) => f + i.answered, 0) : 0;
                    data.push(answered)
                }
            }else{
                data.push(0,0,0,0,0,0)
            }
        }
        setaqReport({...aqReport, stat: params.status, date: date, quarter: params.quarter, sequence: params.sequence, interval: params.label, label: label, data: data})
    }, 2000); 
}

export const fetchGrowthRate = (aid, licensure_id, setGrowthRate) => {
    setTimeout(async() => {
        let fetch = await rqx.get(`<<tool>>/${env()}/examActivity/growthRate`, {aid: aid, licensure_id: licensure_id})
        if(fetch.msg === 'success'){
            let gr = []
            for (let i = 0; i < fetch.gr.length; i++) {
                let ts = fetch.gr[i].subject.split(" ").length;
                let subject = null;
                if(ts === 1){
                    let n = fetch.gr[i].subject.substring(0, 3)
                    subject = n.toUpperCase();
                }else{
                    subject = fetch.gr[i].subject.replace(/[^A-Z]/g, '')
                }
                gr.push({'label': subject, 'grade': fetch.gr[i].grade})
            }
            setGrowthRate({stat: 'has-exam', gr: gr, ptp: fetch.ptp, avg: fetch.avg})
        }
    }, 3000); 
}

export const fetchProgressReport = (params, setPrsp, view) => {
    let dateStart = [];
    let dateEnd = [];
    let label = [];
    let date =  params.from === 'dashboard' ? moment().subtract(3, 'days').format('Y-MM-DD') : params.date;
    for (let i = 0; i < 6; i++) {
        let start =  moment(date).add(i, params.interval).format('Y-MM-DD');
        let end = (params.interval === 'weeks' ? moment(start).add(6, 'days').format('Y-MM-DD') : moment(start).endOf(params.interval).format('Y-MM-DD'));
        dateStart.push(start);
        dateEnd.push(end);
        label.push(moment(start).format('M-D-YY'))
    }
    let colors = ['#5B8DFD', '#C85BFD', '#FDD25B', '#5BCBFD', '#FD5BAC', '#FD8D5B']
    if(dateStart.length === 6){
        setTimeout(async() => {
            if(params.status === 'has-exam'){
                let fetch = await rqx.get(`<<tool>>/${env()}/examActivity/progressReport`, {aid: params.aid, licensure_id: params.licensure_id, dateStart: dateStart, dateEnd: dateEnd})
                if(fetch.msg === 'success'){
                    let data = [];
                    for (let i = 0; i < fetch.pr.length; i++) {
                        let ts = fetch.pr[i].subject.split(" ").length;
                        let subject = null;
                        if(ts === 1){
                            let n = fetch.pr[i].subject.substring(0, 3)
                            subject = n.toUpperCase();
                        }else{
                            subject = fetch.pr[i].subject.replace(/[^A-Z]/g, '')
                        }
                        if(view === 'desktop'){
                            data.push({label: subject, data: fetch.pr[i].dgr, borderColor: colors[i], backgroundColor: colors[i], tension: 0.2})
                        }else{
                            data.push({label: subject, data: fetch.pr[i].dgr, borderColor: colors[i], backgroundColor: colors[i], tension: -0, pointHitRadius: 70})
                        }
                    }
                    setPrsp({stat: 'has-exam', interval: params.label, date: date, quarter: params.quarter, sequence: params.sequence, label: label, data: data})
                }
            }else{
                let data = [
                    {label: 'AFAR', data: [0, 15, 55, 75, 45, 56], borderColor: colors[0], backgroundColor: colors[0], tension: 0.4},
                    {label: 'AUD', data: [0, 25, 65, 85, 25, 68], borderColor: colors[1], backgroundColor: colors[1], tension: 0.4},
                    {label: 'FAR', data: [10, 15, 20, 25, 30, 45], borderColor: colors[2], backgroundColor: colors[2], tension: 0.4},
                    {label: 'MAS', data: [2, 8, 28, 50, 68, 89], borderColor: colors[3], backgroundColor: colors[3], tension: 0.4},
                    {label: 'RFBT', data: [0, 36, 38, 76, 89, 29], borderColor: colors[4], backgroundColor: colors[4], tension: 0.4},
                    {label: 'TAX', data: [3, 23, 35, 93, 45, 67], borderColor: colors[5], backgroundColor: colors[5], tension: 0.4},
                ]
                setPrsp({stat: 'no-exam', interval: params.label, date: date, quarter: params.quarter, sequence: params.sequence, label: label, data: data})
            }
        })
    }
}


export const convertTake = (n) => {
    return["st","nd","rd"][((n+90)%100-10)%10-1]||"th";
}