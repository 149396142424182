import { Box, Button, Dialog, DialogActions, DialogTitle, DialogContent, IconButton, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Close } from '../../../../../core/global/Icons';
export const Retake = ({isDialog, overview, setIsDialog, urlParam}) => {
    const history = useHistory()
    const handleRetake = () => {
        history.push(`/biz/take-exam?page=questionnaire&sid=${urlParam.get('sid')}&stid=${urlParam.get('stid')}&sname=${urlParam.get('sname')}&tid=${urlParam.get('tid')}&tname=${urlParam.get('tname')}&setId=${urlParam.get('setId')}&setName=${urlParam.get('setName')}&setCategory=${urlParam.get('setCategory')}&type=exam&take=${overview.summary.take + 1}&etid=${urlParam.get('etid')}&esid=${urlParam.get('esid')}&eaid=${urlParam.get('eaid')}&from=exam`)
    }
    const handleShowAnswers = () => {
        history.push(`/biz/take-exam?page=show-answers&sid=${urlParam.get('sid')}&stid=${urlParam.get('stid')}&sname=${urlParam.get('sname')}&tid=${urlParam.get('tid')}&tname=${urlParam.get('tname')}&setId=${urlParam.get('setId')}&setName=${urlParam.get('setName')}&setCategory=${urlParam.get('setCategory')}&type=${urlParam.get('type')}&take=${urlParam.get('take')}&etid=${urlParam.get('etid')}&esid=${urlParam.get('esid')}&eaid=${overview.summary.id}&tq=${overview.summary.tq}&from=exam`)
    }
    return (
        <Dialog open={isDialog.open} fullWidth maxWidth={'xs'} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title" sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
                <Box display="flex" textAlign="center" alignItems="center" width="100%" p="10px" borderRadius="10px" mr="10px">
                    <Box fontSize="18px" fontWeight="bold" color="#1B1B1B">Challenge yourself?</Box>
                </Box>
                <Box sx={{ml: 'auto'}}>
                    <IconButton onClick={()=>setIsDialog({open: false, name: null})}><Close /></IconButton>
                </Box>
            </DialogTitle>
            <DialogContent sx={{textAlign: 'center'}}>
                <Box mt="10px" mb="10px" color="#000" fontSize="16px">If you want to challenge yourself, Retake now without viewing the right answers.?</Box>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={2} display="flex" justifyContent="center" width="100%" mb="15px">
                    <Button variant="contained" onClick={handleRetake}>Yes, Retake Now</Button>
                    <Button variant="contained" color="secondary" onClick={handleShowAnswers}>No, Show Answers</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}