import { Box, InputLabel, TextField, MenuItem, Button, IconButton, Skeleton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useState } from 'react';
import moment from 'moment';
import { Waypoint } from 'react-waypoint';
import { rqx } from '../../../../core/request/API';
import { env } from '../../../../core/Env';
let hasValue = {};
export const Index = ({__SESSION}) => {
    let intervals = ['Daily', 'Weekly', 'Monthly', 'Yearly'];
    const types = [{id: 0, value: 'Licensures'}, {id: 1, value: 'Certifications'}]
    const dateTypes = [{openTo: 'day', views: ['year', 'month', 'day'], format: 'MM-DD-YYYY'}, {openTo: 'day', views: ['year', 'month', 'day'], format: 'MM-DD-YYYY'}, {openTo: 'month', views: ['year', 'month' ], format: 'M-YYYY'}, {openTo: 'month', views: ['year'], format: 'Y'}]
    const [input, setInput] = useState({type: '', interval: 0, from: undefined, to: ''})
    const [selectDType, setselectDType] = useState(dateTypes[0]);
    const [generate, setGenerate] = useState(true) 
    const [reports, setReports] = useState({status: 'no-data', data: []})
    const [loading, setLoading] = useState(false)
    const [more, setMore] = useState(false)
    const handleInput = (value, name) => {
        console.log(value, name)
        if(name === 'interval'){
            setInput({...input, interval: value, from: undefined, to: ''})  
            if(value === 1){
                var now = moment();
                let data = generateWeekly(now);
                setInput({...input, interval: value, from: moment(data[0]).format('MM/DD/YYYY'), to: moment(data[1]).format('MM/DD/YYYY')})
            }else{
                setselectDType(dateTypes[value])
            }
        }else{
            setInput({...input, [name]: value})
            handleValidation(name, value)
        }
    }
    const generateWeekly = (now) => {
        var monday = now.clone().weekday(1);
        var sunday = now.clone().weekday(7);
        return [monday, sunday];
    }
    const handlePrevNext = (action) => {
        var now = (action === 'previous' ? moment(input.from).subtract(7, 'days') : moment(input.to).add(1, 'days'));
        let data = generateWeekly(now);
        setInput({...input, from: moment(data[0]).format('MM/DD/YYYY'), to: moment(data[1]).format('MM/DD/YYYY')});
    }
    const handleValidation = (name, value) => {
        hasValue[name] = value
        let d = Object.keys(hasValue).every((k) => hasValue[k] !== null)
        console.log(hasValue)
        if(Object.keys(hasValue).length === 2 && d){
            setGenerate(false)
        }else{
            setGenerate(true)
        }
    }
    const generateAQ = async() => {
        setLoading(true)
        fetchReports(0);
    }
    const fetchReports = async(lastId) => {
        let start = input.interval === 2 ? moment(input.from).startOf('month').format('YYYY-MM-DD') : (input.interval === 3 ? moment(input.from).startOf('year').format('YYYY-MM-DD') : moment(input.from).format('YYYY-MM-DD'))
        let end = input.interval === 1 ? moment(input.to).format('YYYY-MM-DD') : ( input.interval === 2 ? moment(input.from).endOf('month').format('YYYY-MM-DD') : (input.interval === 3 ? moment(input.from).endOf('year').format('YYYY-MM-DD') : moment(input.from).format('YYYY-MM-DD')));
        let params = {company_id: __SESSION.data.uaccess[0].company_id, tool_id: process.env.REACT_APP_TID_BIZ, 'start': start, 'end': end, 'lastId': lastId};
        const fetch = await rqx.get(`<<tool>>/${env() === 'local' ? 'dev' : env()}/user/getUsersAQ`, params)
        console.log(fetch)
        if(fetch.msg === 'success'){
            setMore(fetch.data.length > 0 ? true : false)
            if(lastId === 0){
                setReports({status: fetch.data.length > 0 ? 'has-data' : 'no-data', data: fetch.data});
                setLoading(false)
            }else{
                setReports({...reports, data: reports.data.concat(fetch.data)});
            }
        }
    }
    const loadMore = () => {
        let lastData = reports.data[reports.data.length - 1];
        let lastId = lastData.created_by;
        fetchReports(lastId)
    }
    return (
        <>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="16px">
            <Box display="flex">
                <Box sx={{'& .MuiTextField-root': { mr: 1, width: '25ch' }}} mb="5px">
                    <InputLabel sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>Type</InputLabel>
                    <TextField id="outlined-select-currency" name="licensure" size="small" select onChange={(e)=>handleInput(e.target.value, 'type')}>
                        {
                            types.map((t,i) => (
                                <MenuItem key={i} value={t.id}>{t.value}</MenuItem>
                            ))
                        }
                    </TextField>
                </Box>
                <Box sx={{'& .MuiTextField-root': { mr: 1, width: '25ch' }}} mb="5px">
                    <InputLabel sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>Interval</InputLabel>
                    <TextField id="outlined-select-currency" name="interval" size="small" value={input.interval} select onChange={(e)=>handleInput(e.target.value, 'interval')}>
                        {
                            intervals.map((interval,i) => (
                                <MenuItem key={i} value={i}>{interval}</MenuItem>
                            ))
                        }
                    </TextField>
                </Box>
                <Box width="25ch" mr="10px">
                    <InputLabel sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>From:</InputLabel>
                    {
                        input.interval === 1 ? (
                            <TextField fullWidth name="from" size="small" value={input.from} disabled={true} sx={{input: {background: '#dbdde0', borderRadius: '5px'}}}/>
                        ) : (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker openTo={selectDType.openTo} views={selectDType.views} slotProps={{ textField: { size: 'small' } }} inputFormat={selectDType.format} value={input.from} onChange={(e)=>handleInput(e, 'from')} renderInput={(params) =><TextField size="small" name="from" {...params} margin="dense" />} />
                            </LocalizationProvider>
                        )
                    }
                </Box>
                {
                    input.interval === 1 &&
                    <Box width="25ch">
                        <InputLabel sx={{fontSize: '14px', color: '#333333', mb: '5px'}}>To:</InputLabel>
                        <TextField fullWidth name="to" size="small" value={input.to} disabled={true} sx={{input: {background: '#dbdde0', borderRadius: '5px'}}}/>
                    </Box>
                }
                {
                    input.interval === 1 && 
                    <Box mt="28px" ml={1}>
                        <IconButton size="small" onClick={()=>handlePrevNext('previous')}><KeyboardArrowLeftIcon /></IconButton>
                        <IconButton size="small" onClick={()=>handlePrevNext('next')}><KeyboardArrowRightIcon /></IconButton>
                    </Box>
                }
            </Box>
            <Box mt="20px"><Button variant="contained" disabled={generate} onClick={generateAQ}>Generate</Button></Box>
        </Box>
        <Box display="flex" justifyContent="space-between" color="#283745" fontWeight={600} p="16px">
            <Box width="20%">Date</Box>
            <Box width="20%">Firstname</Box>
            <Box width="20%">Lastname</Box>
            <Box width="20%">Name of Exam</Box>
            <Box width="20%">Total no. of Questions</Box>
        </Box>
        {
            loading ? (
                [0,1,2,3,4,5].map(i => (
                    <Skeleton key={i} variant="rounded" width="100%" height={60} sx={{mb: '12px'}}/>
                ))
            ) : (
                reports.status === 'no-data' ? (
                    <Box width="100%" height="100px" border="1px solid #ebebeb" borderRadius="12px" display="flex" alignItems="center" justifyContent="center" fontSize="14px">
                        No data available.
                    </Box>
                ) : (
                    reports.data.map((r,i) => (
                        <Box key={i} display="flex" border="1px solid #ebebeb" borderRadius="10px" p="16px" fontSize="14px" mb="12px">
                            <Box width="20%">{moment(r.created_at).format('YYYY-MM-DD')}</Box>
                            <Box width="20%">{r.first_name}</Box>
                            <Box width="20%">{r.last_name}</Box>
                            <Box width="20%">Licensure: CPA</Box>
                            <Box width="20%">{r.answered}</Box>
                        </Box>
                    ))
                )
            )
        }
        {
            more &&
            <Waypoint onEnter={loadMore} />
        }
        </>
    )
}