import { useState } from "react"

export const ToolStates = () => {
    const [account, setAccount] = useState({lastname: '', firstname: '', middlename: '', suffix: '', profile: '', civil_status: '', gender: '', birthdate: ''})
    const [preload, setPreload] = useState({loader:false, logo:''}) 
    const [prefetch, setPrefetch] = useState(false)
    const [__SESSION, setSession] = useState({ainfo: null, pinfo: null, licensure: null})
    const [subPlan, setSubPlan] = useState([])
    const [toolOwned, setToolOwned] = useState([]);
    const [userId, setUserId] = useState()
    const [globalLoader, setGlobalLoader] = useState(true)
    const [validateAccount, setValidateAccount] = useState({data:'', count:0})
    return {
        account: {data: account, set: setAccount},
        preload: {data: preload, set: setPreload},
        prefetch: {data: prefetch, set: setPrefetch},
        __SESSION: {data: __SESSION, set: setSession},
        subPlan: {data: subPlan, set: setSubPlan},
        userId: {data: userId, set: setUserId},
        globalLoader: {data: globalLoader, set: setGlobalLoader},
        toolOwned: {data: toolOwned, set: setToolOwned},
        validateAccount: {data: validateAccount, set: setValidateAccount}
    }
}