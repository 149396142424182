import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useHistory } from 'react-router-dom';
import HowTo from '../../../../../assets/images/takeExam/how-to-pass.png';
export const HTPTE = ({exam, urlParam, setExam}) => {
    const history = useHistory()
    return (
        <Dialog open={exam.isDialog} maxWidth={'sm'} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" PaperProps={{ sx: { borderRadius: "12px", width:'468px', background: '#fff'}}}>
            <DialogTitle id="alert-dialog-title">
                <Box display="flex" justifyContent="center" mt="24px">
                    <img src={HowTo} alt="icon" height="100%"/>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box fontSize="20px" fontWeight={600} color="#313131" mb="20px" mt="20px">How to pass the Exam?</Box>
                <Box fontWeight={400}>Keep answering until all your answered questions are <Box component="span" style={{color: '#008F5D', fontWeight: 700}}>TWICE CORRECT</Box> and <Box component="span" style={{color: '#008F5D', fontWeight: 700}}>TWICE SURE.</Box></Box>
            </DialogContent>
            <DialogActions sx={{display: 'flex', justifyContent: 'flex-end', width: '100%', textAlign: 'center', p: '20px'}}>
                <Button variant="outlined" sx={{color: '#313131', border: '1px solid #E5EBF6'}} onClick={()=>setExam({...exam, isDialog: false, dialog: null})}>Back</Button>
                <Button variant="contained"  onClick={() => history.push(`/biz/take-exam?page=questionnaire&sid=${urlParam.get('sid')}&stid=${urlParam.get('stid')}&sname=${urlParam.get('sname')}&tid=${urlParam.get('tid')}&tname=${urlParam.get('tname')}&setId=${exam.id}&setName=${exam.name}&setCategory=${exam.category}&type=exam&take=1`)}>Got it!</Button>
            </DialogActions>
        </Dialog>
    )
}