import { Box, Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';
export const NotAnswered = ({exam, setExam}) => {
    return (
        <>
        <Dialog open={exam.isDialog} fullWidth maxWidth={'xs'} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent>
                <Box color="#000" fontSize="15px" mt="20px" textAlign="center">You forgot to answer question no.: 
                    <Box component="span" bgcolor="#131e34" color="#fff" mr="5px" p="5px 15px" borderRadius="5px" width="40px">{exam.notAnswered}</Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setExam({...exam, isDialog: false, status: null})}>Okay</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}