import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@mui/material'
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { CircleCheck } from '../../../core/global/Icons';
import { ToolContext } from '../../../core/context/ToolContext'
import { SnakeLoader } from '../../../core/loader/SnakeLoader'
import { rqx } from '../../../core/request/API';
import { env } from '../../../core/Env';
import moment from 'moment';
export const WTK = ({__SESSION, pslztn}) => {
    const history = useHistory()
    const { home_state } = useContext(ToolContext)
    const myPlan = home_state.myPlan
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(dayjs(moment().format('YYYY-MM-DD')))
    const [hasError, setError] = useState({value: false, msg: null});
    const [open, setOpen] = useState(false);
    const handleChange = (e) => {
        setValue(e)
        setError({value: false, msg: null})
    }
    const handleClose = () => {
        setOpen(false);
    }
    const handleSubmit = async() => {
        let Dnow = moment(new Date()).format('YYYY-MM-DD');
        let Dend = moment(value.$d.toISOString()).format('YYYY-MM-DD');
        let days = moment(Dend).diff(Dnow,'days');
        if(days === 0){
            setError({value: true, msg: 'You only have '+ days + ' day/s to prepare for CPA Board Exam. We recommend you to select a different date.'});
        }else{
            setLoading(true);
            let remaining = Math.round(moment(Dend).diff(new Date(),'days') / 5);
            let day = moment().add(remaining, 'days');
            let targetTQ = [1800, 3600, 5400, 7200, 9000];
            let totalAQ = (myPlan.data.plan === null ? 0 : myPlan.data.plan.aq);
            let taken = totalAQ;
            let level = 0;
            let data = [];
            let pl =[];
            for (let i = 0; i < 5; i++) {
                pl.push({level: i+1, target_question: targetTQ[i], accomplish_date: moment(day).format('YYYY-MM-DD'), status: 0, created_at: moment().format('YYYY-MM-DD hh:mm:ss')});
                data.push({
                    'level': i+1,
                    'target': targetTQ[i], 
                    'taken': level === i ? totalAQ >= targetTQ[i] ? targetTQ[i] : taken : 0,
                    'percentage': level === i ? totalAQ >= targetTQ[i] ? 100 : Math.round(taken / targetTQ[i] * 100) : 0, 
                    'date_accomplish': moment(day).format('YYYY-MM-DD'), 
                    'status': (myPlan.data.plan === null ? 0 : myPlan.data.progress_level[i].status)
                })
                day = moment(day).add(remaining, 'days');
                if(totalAQ >= targetTQ[i]){
                    taken = totalAQ - targetTQ[i];
                    level = level + 1;
                }
            }
            let params = {aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), pl: pl, month: moment(Dend).format('M'), year: moment(Dend).format('YYYY'), 'created_at': moment().format('YYYY-MM-DD hh:mm:ss')}
            let submit = await rqx.post(`<<tool>>/${env()}/userPlan/create`, params)
            if(submit.msg === 'success'){
                let duration = moment(Dend).diff(new Date(),'days');
                myPlan.set({...myPlan.data, isLoading: false, plan: {id: submit.planId, target: Dend, days: duration, aq: (myPlan.data.plan === null ? 0 : myPlan.data.plan.aq)}, progress_level: data})
                setOpen(true);
                history.push('/biz')
            }else{
                redirect()
            }
            let d = new Date()
            const strg = [{aid: __SESSION.ainfo.aid, env: env(), feature_name: `WTK`, date_added: `${d.getMonth()+1}${d.getUTCDate()}${d.getUTCFullYear()}`}]
            localStorage.setItem('sip_biz_session_'+__SESSION.ainfo.aid, JSON.stringify(strg))
            pslztn.set(null)
        }
    }
    const redirect = () => {
        setTimeout(() => {
            history.push('/biz')
            setOpen(false);
        }, 1000);
    }
    return (
        <Box>
            <Dialog open={true} PaperProps={{ sx: { borderRadius: "12px", width:'454px'}}}>
                <DialogTitle sx={{fontSize:'16px', fontWeight:'600'}}>When do you plan to take the CPA Board exam?</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar value={value} minDate={dayjs(moment().format('YYYY-MM-DD'))} onChange={(e)=>handleChange(e)}/>
                    </LocalizationProvider>
                    <Box fontSize={14}>{hasError.msg}</Box>
                </DialogContent>
                <DialogActions sx={{padding:'0px 24px 24px 0px'}}>
                    <Button variant="outlined" color="secondary" onClick={()=>history.push('/psl')}>Cancel</Button>
                    {
                        loading ? (
                            <Box bgcolor="#11783c" borderRadius="6px" p="6px 15px">
                                <Box height="25px" display="flex" alignItems="center" justifyContent="center"><SnakeLoader size="10px" bg="#fff" distance="0.3rem" /></Box>
                            </Box>
                        ) : (
                            <Button variant="contained" color="primary" sx={{padding: '6px 24px'}} onClick={handleSubmit} disabled={loading ? true : false}>Set</Button>
                        )
                    }
                </DialogActions>
            </Dialog>
            <Snackbar open={open} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} autoHideDuration={6000} onClose={handleClose}>
                <Alert variant="filled"  severity="success" icon={<CircleCheck fontSize="inherit" fill="#008F5D"/>} sx={{ width: '100%', color: '#008F5D', bgcolor: '#E8F5E9', border: '1px solid #008F5D'}}>
                    Date successfully set.
                </Alert>
            </Snackbar>
        </Box>
    )
}