import { Box, Breadcrumbs, Button, Link, Grid, Skeleton, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchTopics } from '../global/Functions';
import { All } from './lists/All'
import { Recent } from './lists/Recent'
import { rqx } from '../../../../core/request/API';
import { env } from '../../../../core/Env';
export const Index = ({urlParam, __SESSION}) => {
    const history = useHistory()
    const [loading, setLoading] = useState(true);
    const [topics, setTopics] = useState([]);
    const [lists, setLists] = useState('All')
    const [recent, setRecent] = useState([])
    const num = [20,40,60,80,100]
    const handleAction = async() => {
        let l = (lists === 'All' ? 'Recent' : 'All');
        if(l === 'Recent' && recent.length === 0){
            setLoading(true)
            let fetch = await rqx.get(`<<tool>>/${env()}/examTopic/recent`, {stid: parseInt(urlParam.get('stid')), aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1])})
            if(fetch.length > 0){
                let data = []
                for (let i = 0; i < fetch.length; i++) {
                    let filter = topics.filter(f => f.id === fetch[i].id)
                    if(filter.length > 0){
                        data.push({id: fetch[i].id, name: fetch[i].name, gr: filter[0].gr})
                    }
                }
                setRecent(data);
            }
        }
        setLists(lists === 'All' ? 'Recent' : 'All')
        setLoading(false);
    }
    useEffect(() => {
        setTimeout(() => {
            fetchTopics(parseInt(urlParam.get('stid')), parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1]), setTopics, setLoading);
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
        <Box width="100%" height="100%">
            <Box p="40px 72px 24px 72px" borderBottom="1px solid #E5EBF6" sx={{boxShadow: '0px 2px 4px 0px #32464414'}}>
                <Breadcrumbs aria-label="breadcrumb" sx={{fontSize: '14px', color: '#7C9F83', mb: '10px'}}>
                    <Link underline="none" color="#7C9F83" href="#" onClick={() => history.push(`/biz/take-exam`)} sx={{fontSize: '14px', fontWeight: 400}}>Take Exam</Link>
                    <Typography color="text.primary" sx={{fontSize: '14px', fontWeight: 400}}>{urlParam.get('sname')}</Typography>
                </Breadcrumbs>
                <Box fontWeight={700} fontSize="24px">Topics</Box>
            </Box>
            <Box p="40px 72px">
                <Button variant="contained" color="primary" sx={{mb: '40px'}} onClick={handleAction}>{lists === 'All' ? 'Recent' : 'All'} Topic</Button>
                <Grid container spacing={2}>
                    {
                        loading ? (
                            [0,1,2,3,4,5].map(k => (
                                <Grid key={k} item xs={12} md={6} lg={4}>
                                    <Skeleton key={k} variant="rounded" width={'100%'} height={168} sx={{borderRadius: '10px', mb: 2}} />
                                </Grid>
                            ))
                        ) : (
                            lists === 'All' ? <All num={num} topics={topics} urlParam={urlParam}/> : <Recent num={num} recent={recent} urlParam={urlParam}/>
                        )
                    }
                </Grid>
            </Box>
        </Box>
    )
}