import { useEffect } from 'react';
import { Box, Divider } from '@mui/material';
export const Duration = ({exam, timer, setTimer, result}) => {
    const handleActualTime = (time) => {
        setInterval(() => {
            time++;
            let hrs =  Math.floor(time / 3600)
            let mins = Math.floor((time - (hrs * 3600)) / 60)
            let sec = (time - (hrs * 3600) - (mins * 60)) 
            let hour = hrs < 10 ? "0"+ hrs : hrs;
            let minutes = mins < 10 ? "0" + mins : mins;
            let seconds = sec < 10 ? "0" + sec : sec;
            setTimer({...timer, at: [hour, minutes, seconds]});
        }, 1000)
    }
    useEffect(() => {
        setTimeout(() => {
            handleActualTime(0);
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
        <Box padding="24px">
            <Box display="flex" alignItems="center" mb="24px">
                <Box display="flex" flexDirection="column">
                    <Box bgcolor="#F6F9FF" p="12px 16px" fontSize="24px" fontWeight={700} mb="14px" borderRadius="6px">{result.time_spent === null ? timer.at[0] : result.time_spent[0]}</Box>
                    <Box fontSize="12px" color="#A2A3A9" textAlign="center">HRS</Box>
                </Box>
                <Box fontSize="17px" fontWeight={700} ml="12px" mr="12px" mb="24px">:</Box>
                <Box display="flex" flexDirection="column">
                    <Box bgcolor="#F6F9FF" p="12px 16px" fontSize="24px" fontWeight={700} mb="14px" borderRadius="6px">{result.time_spent === null ? timer.at[1] : result.time_spent[1]}</Box>
                    <Box fontSize="12px" color="#A2A3A9" textAlign="center">MIN</Box>
                </Box>
                <Box fontSize="17px" fontWeight={700} ml="12px" mr="12px" mb="24px">:</Box>
                <Box display="flex" flexDirection="column">
                    <Box bgcolor="#F6F9FF" p="12px 16px" fontSize="24px" fontWeight={700} mb="14px" borderRadius="6px">{result.time_spent === null ? timer.at[2] : result.time_spent[2]}</Box>
                    <Box fontSize="12px" color="#A2A3A9" textAlign="center">SEC</Box>
                </Box>
            </Box>
            <Box fontSize="12px" fontWeight={400} textAlign="center">Suggested time to answer all questions:</Box>
            <Box fontSize="14px" fontWeight={600} textAlign="center" mb="12px">{exam.st}</Box>
            <Divider sx={{mb: '12px'}}/>
            <Box fontSize="14px" fontWeight={400} mb="16px">SED Marking Technique</Box>
            <Box bgcolor="#2EAE84" p="12px 16px" borderRadius="6px" mb="10px" color="#fff" fontSize="12px">
                <Box mb="10px">SURE</Box>
                <Box>You are 100% confident in your answer.</Box>
            </Box>
            <Box bgcolor="#0070EF"p="12px 16px" borderRadius="6px" mb="10px" color="#fff" fontSize="12px">
                <Box mb="10px">EASY</Box>
                <Box>You are familiar with questions but has second thoughts about the correct answers.</Box>
            </Box>
            <Box bgcolor="#FA3E3E" p="12px 16px" borderRadius="6px" mb="10px" color="#fff" fontSize="12px">
                <Box mb="10px">DIFFICULT</Box>
                <Box>You have no idea how to answer the questions.</Box>
            </Box>
            <Box bgcolor="#F6F9FF" borderRadius="6px" p="12px 16px">
                <Box fontWeight="bold" fontSize="12px">Note:</Box>
                <Box fontSize="12px">Repeat answering the questions until all are marked as sure. Questions that are marked SURE and correct twice will be removed from the lists. Questions marked as EASY and DIFFICULT are the only ones to be retaken. Focus your time on the questions which you find more difficult, while mastering the easier questions at once.</Box>
            </Box>
        </Box>
    )
}