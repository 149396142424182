import { useState, useEffect } from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import { rqx } from '../../../../core/request/API';
import { env } from '../../../../core/Env';
import { convertTime } from '../global/Functions';
import { Question } from './Question'
import { Duration } from './Duration'
import { NotAnswered } from './modals/NotAnswered';
import { Submit } from './modals/Submit';
import { Result } from './modals/Result';
import { Success } from './modals/Success';
export const Index = ({urlParam, __SESSION}) => {
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState({esstatus: null, rq: null})
    const [timer, setTimer] = useState({at: ['00', '00', '00'], status: null})
    const [exam, setExam] = useState({no:0, tq: 0, aq: [], st: '00:00:00', answered: 0, notAnswered: 0, status: null, isDialog: false})
    const [questions, setQuestions] = useState([]);
    const [question, setQuestion] = useState({});
    const [result, setResult] = useState({open: false, score: 0, avg: 0, time_spent: null, status: null, sed: [], etid: null, esid: null, eaid: null})
    const [category, setCategory] = useState({index: null, isOpen: false})
    const fetchData = async() => {
        let fetch = await rqx.get(`<<tool>>/${env()}/question/examQuestions`, {'set_id': parseInt(urlParam.get('setId')), 'esid': parseInt(urlParam.get('esid')), 'take': parseInt(urlParam.get('take'))})
        if(fetch.aq.length >= 1){
            let st = convertTime(fetch.at);
            setExam({...exam, tq: fetch.tq, aq: fetch.aq, st: st})
            setQuestions(fetch.questions)
            setQuestion(fetch.questions[0])
            setLoading(false);
        }
    }
    const fetchQuestions = async(key) => {
        setLoading(true);
        let findQ = questions.filter(f=> f.id === exam.aq[key].id)
        if(findQ.length === 0){
            let fetch = await rqx.get(`<<tool>>/${env()}/question/examQuestion`, {'id': exam.aq[key].id})
            if(fetch !== 'not-exist'){
                setQuestions(questions.concat(fetch))
                setQuestion(fetch)
            }
        }else{
            console.log(findQ[0])
            setQuestion(findQ[0])
        }
        let answered = questions.filter(q => q.hasOwnProperty('u_answer'));
        setExam({...exam, no: key, answered: answered.length})
        setLoading(false)
    }
    useEffect(() => {
        setTimeout(() => {
            fetchData();
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
        <Box display="flex" width="100%" height="100%">
            <Box width="100%" borderRight="1px solid #E5EBF6">
                <Box p="40px 72px">
                    <Typography color="#313131" fontSize="24px" fontWeight={700} mb="24px" >{urlParam.get('sname')}</Typography>
                    <Box display="flex" alignItems="center" mb="24px">
                        <Box border="1px solid #107038" bgcolor="#07a42a1a" color="#107038" fontSize="14px" fontWeight={400} p="2px 12px" borderRadius="100px" mr="16px">{urlParam.get('setCategory') === 1 ? 'Basic' : urlParam.get('setCategory') === 2 ? 'Intermediate' : 'Advance'}: Set {urlParam.get('setName')}</Box>
                        <Box fontSize="14px" fontWeight={400}>{urlParam.get('tname')}</Box>
                    </Box>
                    <Box fontSize="14px" fontWeight={400}>Directions: Kindly answer and rate your answers using SURE, EASY and Difficult categories on each questions. </Box>
                </Box>
                <Box bgcolor="rgba(246, 249, 255, 1)" p="12px 72px" color="#313131" fontWeight={600}>Question Taken: {exam.answered}/{exam.tq}</Box>
                <LinearProgress  variant="determinate" color="info" value={exam.answered/exam.tq*100} sx={{bgcolor: '#E5EBF6', display: 'block', height: '4px', overflow: 'hidden', mb: '8px', position: 'relative'}} />
                <Box p="28px 72px">
                    <Question category={category} exam={exam} loading={loading} setExam={setExam} setCategory={setCategory} setQuestions={setQuestions} setQuestion={setQuestion} questions={questions} question={question} fetchQuestions={fetchQuestions} urlParam={urlParam}/>
                </Box>
            </Box>
            <Box width="392px">
                <Duration exam={exam} timer={timer} setTimer={setTimer} result={result}/>
            </Box>
            {
                exam.status === 'not-answered' &&
                <NotAnswered exam={exam} setExam={setExam}/>
            }
            {
                exam.status === 'submit' &&
                <Submit exam={exam} urlParam={urlParam} questions={questions} status={status} setExam={setExam} __SESSION={__SESSION} timer={timer} setTimer={setTimer} setResult={setResult} setStatus={setStatus}/>
            }
            {
                exam.status === 'success' &&
                <Success exam={exam} urlParam={urlParam} result={result}/>
            }
            {
                exam.status === 'result' && 
                <Result exam={exam} result={result} urlParam={urlParam} __SESSION={__SESSION} setStatus={setStatus}/>
            }
            
        </Box>
    )
}