import { Box, Button, IconButton, Dialog, DialogContent, TextField } from '@mui/material';
import { Close } from '../../../../../../core/global/Icons';
import { calculateAVG } from '../../Functions';
export const CAS = ({avgSpeed, avg, setAvg}) => {
    const handleChange = (e) => {
        setAvg({...avg, tq: e.target.value});
    }
    const handleGenerate = () => {
        calculateAVG(avgSpeed, avg, setAvg)
    }
    return (
        <Dialog open={avg.isDialog} onClose={()=>setAvg({...avg, isDialog: false, tq: 0, total: 0})} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogContent>
                    <Box display="flex" justifyContent="end">
                        <IconButton onClick={()=>setAvg({...avg, isDialog: false, tq: 0, total: 0})}><Close /></IconButton>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Box>
                            <Box mb={2}>Type the no. of questions your prefer:</Box>
                            <Box display="flex" alignItems="center">
                                <TextField type="number" id="outlined-basic" onChange={(e)=>handleChange(e)} variant="outlined" size="small"/>
                                <Box ml={1}>
                                    <Button variant="contained" onClick={handleGenerate} disabled={avg.tq > 0 ? false : true}>Generate</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box mt={2}>Total Average Speed: <Box component="span" fontWeight="bolder">{avg.total === "" ? "0 hr. and 0 min.": avg.total}</Box></Box>
                </DialogContent>
        </Dialog>
    )
}