import { Box } from "@mui/system";

export const showSteps = () => {
    let steps = [
        {
            target: '.first-step',
            content:<Box>
                        <Box display='flex' justifyContent='space-between'>
                            <Box fontSize={14} fontWeight={600} color="#313131">Dashboard</Box>
                            <Box fontSize={12} color="#8496BB"> 1/4 </Box>
                        </Box>
                        <Box fontSize={12} mt={2} textAlign="left" color="#313131">Your transaction summary.</Box>
                    </Box>,
            disableBeacon: true,
            placement: 'right',
        },
        {
            target: '.second-step',
            content: <Box>
                        <Box display='flex' justifyContent='space-between'>
                            <Box fontSize={14} fontWeight={600} color="#313131">Take Exam</Box>
                            <Box fontSize={12} color="#8496BB"> 2/4  </Box>
                        </Box>
                        <Box fontSize={12} mt={2} textAlign="left" color="#313131">List of exams that will help you pass your actual CPA board exam.</Box>
                    </Box>,
            placement: 'right',
        },
        {
            target: '.third-step',
            content: <Box>
                        <Box display='flex' justifyContent='space-between'>
                            <Box fontSize={14} fontWeight={600} color="#313131">Leader Board</Box>
                            <Box fontSize={12} color="#8496BB"> 3/4  </Box>
                        </Box>
                        <Box fontSize={12} mt={2} textAlign="left" color="#313131">List of users with the highest number of sure and correct answers.</Box>
                     </Box>,
            placement: 'right',
        },
        {
            target: '.sixth-step',
            content:<Box>
                        <Box display='flex' justifyContent='space-between'>
                            <Box fontSize={14} fontWeight={600} color="#313131">Get App</Box>
                            <Box fontSize={12} color="#8496BB"> 4/4  </Box>
                        </Box>
                        <Box fontSize={14} mt={2} textAlign="left">Download app for easy access.</Box>
                    </Box>,
            placement: 'top',
        },
    ]; 
    return steps
};