import { Box, Button, Divider, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { rqx } from '../../../../../core/request/API';
import { env } from '../../../../../core/Env';
import { Graph } from '../../global/Graph';
export const Result = ({exam, result, urlParam, __SESSION, setStatus}) => {
    const history = useHistory()
    const handleRetake = () => {
        window.location.href =`/biz/take-exam?page=questionnaire&sid=${urlParam.get('sid')}&stid=${urlParam.get('stid')}&sname=${urlParam.get('sname')}&tid=${urlParam.get('tid')}&tname=${urlParam.get('tname')}&setId=${urlParam.get('setId')}&setName=${urlParam.get('setName')}&setCategory=${urlParam.get('setCategory')}&type=exam&take=${parseInt(urlParam.get('take')) + 1}&etid=${result.etid}&esid=${result.esid}&eaid=${result.eaid}&from=exam`
    }
    const handleClose = () => {
        history.push(`/biz/take-exam?page=results&sid=${urlParam.get('sid')}&stid=${urlParam.get('stid')}&sname=${urlParam.get('sname')}&tid=${urlParam.get('tid')}&tname=${urlParam.get('tname')}&setId=${urlParam.get('setId')}&setName=${urlParam.get('setName')}&setCategory=${urlParam.get('setCategory')}&type=${urlParam.get('type')}&take=${parseInt(urlParam.get('take')) + 1}&etid=${result.etid}&esid=${result.esid}&eaid=${result.eaid}&from=exam`)
    }
    const fetchActivities = async() => {
        let fetch = await rqx.get(`<<tool>>/${env()}/examActivity/read`, {set_id: urlParam.get('setId'), esid: urlParam.get('esid'), take: urlParam.get('take') + 1, aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1])})
        if(fetch.activities.length > 0){
            setStatus({esstatus: fetch.esstatus, rq: fetch.rq})
        }
    }
    useEffect(() => {
        fetchActivities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return (
        <Dialog open={exam.isDialog} maxWidth={'md'} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" PaperProps={{ sx: { borderRadius: "12px", width:'602px'} }}>
            <DialogTitle id="alert-dialog-title">
                <Box sx={{color: '#313131', fontWeight: 700, textAlign: 'center'}} mb="12px">{urlParam.get('sname')}</Box>
                <Box display="flex" alignItems="center" mb="24px">
                    <Box border="1px solid #107038" bgcolor="#07a42a1a" color="#107038" fontSize="14px" fontWeight={400} p="2px 12px" borderRadius="100px" mr="16px">{urlParam.get('setCategory') === 1 ? 'Basic' : urlParam.get('setCategory') === 2 ? 'Intermediate' : 'Advance'}: Set {urlParam.get('setName')}</Box>
                    <Box fontSize="14px" fontWeight={400}>{urlParam.get('tname')}</Box>
                </Box>
            </DialogTitle>
            <DialogContent sx={{maxHeight: '481px', overflowY: 'auto'}}>
                <Box border="1px solid #E5EBF6" borderRadius="12px" p="16px" mb="16px">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box width="20%">
                            <Graph percentage={Math.round(result.avg)}/>
                        </Box>
                        <Box fontSize="14px" fontWeight={400} ml="16px">
                            {
                                (Math.round(result.avg) >= 75 && exam.st >= (result.time_spent[0]+':'+result.time_spent[1]+':'+result.time_spent[2])) ? (
                                    <>Good job! You take the passing score and you finished the exam quickly</>
                                ) : (Math.round(result.avg) >= 75 && exam.st <= (result.time_spent[0]+':'+result.time_spent[1]+':'+result.time_spent[2])) ? (
                                    <>Good job! You take the passing score but it took you awhile to finish the exam.</>
                                ) : (Math.round(result.avg) >= 75 && exam.st >= (result.time_spent[0]+':'+result.time_spent[1]+':'+result.time_spent[2])) ? (
                                    <>Unfortunately, you did not take the passing score, but you finished the exam quickly.</>
                                ) : (
                                    <>Unfortunately, you did not take the passing score but it took you awhile to finish the exam.</>
                                )
                            }
                        </Box>
                    </Box>
                </Box>
                <Box border="1px solid #E5EBF6" borderRadius="12px" p="16px" fontSize="14px" mb="16px">
                    <Box display="flex" justifyContent="space-between" mb="6px">
                        <Box width="50%">No. of Takes</Box><Box fontWeight={600} width="50%" textAlign="left">{urlParam.get('take')}</Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mb="6px">
                        <Box width="50%">Date Take</Box><Box fontWeight={600} width="50%" textAlign="left">{moment().format('L')}</Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mb="6px">
                        <Box width="50%">Total Question Taken</Box><Box fontWeight={600} width="50%" textAlign="left">{exam.tq}</Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mb="6px">
                        <Box width="50%">Your Score</Box><Box fontWeight={600} width="50%" textAlign="left">{result.score}</Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <Box width="50%">Status</Box><Box width="50%"><Box component="span" borderRadius="100px" bgcolor={result.status === 2 ? '#FFFDE7' : '#f7fcfa'} p="4px 8px" color={result.status === 2 ? '#FA3E3E' : '#008F5D'}>{result.status === 2 ? 'Failed' : 'Passed'}</Box></Box>
                    </Box>
                </Box>
                <Box border="1px solid #E5EBF6" borderRadius="12px" p="16px" fontSize="14px" mb="16px">
                    <Box fontSize="14px" fontWeight={600} mb="6px">SED Marking Technique</Box>
                    <Box display="flex" justifyContent="space-between" textAlign="center" alignItems="center" p="10px" mb="10px" fontSize="12px">
                        <Box width="25%">Category</Box>
                        <Box width="25%">Total Questions</Box>
                        <Box width="25%">Correct</Box>
                        <Box width="25%">Wrong</Box>
                    </Box>
                    <Divider sx={{mb: '8px'}}/>
                    {
                        result.sed.map((s,i) => (
                            <Box key={i} display="flex" justifyContent="space-between" textAlign="center" alignItems="center" sx={{bgcolor: i === 0 ? '#E8F5E9' : i === 1 ? '#CCE0FF' : '#FFEBEE'}} p="10px" borderRadius="10px" mb="10px" fontSize="12px">
                                <Box width="25%" fontWeight={600} textAlign="left" color={i === 0 ? '#008F5D' : i === 1 ? '#0070EF' : '#FA3E3E'}>{i === 0 ? 'SURE' : i === 1 ? 'EASY' : 'DIFFICULT'}</Box>
                                <Box width="25%">{s.total}</Box>
                                <Box width="25%">{s.correct}</Box>
                                <Box width="25%">{s.total - s.correct}</Box>
                            </Box>
                        ))
                    }
                    <Box display="flex" justifyContent="space-between" textAlign="center" alignItems="center" bgcolor="#F6F9FF" p="10px" borderRadius="10px" mb="10px" fontSize="12px" fontWeight={400}>
                        <Box width="25%" textAlign="left">Total</Box>
                        <Box width="25%">{result.sed[0].total + result.sed[1].total + result.sed[2].total}</Box>
                        <Box width="25%">{result.sed[0].correct + result.sed[1].correct + result.sed[2].correct}</Box>
                        <Box width="25%">{(result.sed[0].total - result.sed[0].correct) + (result.sed[1].total - result.sed[1].correct) + (result.sed[2].total - result.sed[2].correct)}</Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions sx={{display: 'flex', justifyContent: 'flex-end', width: '100%', textAlign: 'center', p: '20px'}}>
                <Button variant="outlined" onClick={handleClose}>Close</Button>
                <Button variant="contained" onClick={handleRetake}>Retake</Button>
            </DialogActions>
        </Dialog>
    )
}