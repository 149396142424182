import { useState } from "react"

export const ToolStates = () => {
    const [preload, setPreload] = useState({loader:false, logo:''}) 
    const [prefetch, setPrefetch] = useState(false)
    const [__SESSION, setSession] = useState({ainfo: null, pinfo: null, licensure: null})
    const [toolOwned, setToolOwned] = useState([]);
    return {
        preload: {data: preload, set: setPreload},
        prefetch: {data: prefetch, set: setPrefetch},
        __SESSION: {data: __SESSION, set: setSession},
        toolOwned: {data: toolOwned, set: setToolOwned},
    }
}