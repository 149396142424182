import { Box, Skeleton, LinearProgress } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import moment from 'moment'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
export const Index = ({loading, progress}) => {
    const bg = ['158.36deg, #FFEECE 14.2%, #C5E2D9 114.83%', '158.36deg, #CEDEFF 14.2%, #C5E2D9 114.83%', '158.36deg, #FFEECE 14.2%, #E2C5D7 114.83%', '158.36deg, #DECEFF 14.2%, #C5E2D9 114.83%', '158.36deg, #CEEDFF 14.2%, #C5E2D9 114.83%']
    const useStyles = makeStyles((theme) => ({
            root: {
                "& .MuiLinearProgress-barColorPrimary": {
                    backgroundColor: "#092D77",
                },
            },
        }),
    );
    const classes = useStyles();
    const [currentSlide, setCurrentSlide] = useState(0)
    const [sliderRef, instanceRef] = useKeenSlider(
        {
            loop: false,
            mode: "free-snap",
            slides: {
                perView: 3.5,
                spacing: 20,
            },
            slideChanged(s) {
                setCurrentSlide(s.track.details.rel)
            },
        },
        []
    )
    return (
        loading ? (
            <Box mt="20px" display="flex" justifyContent="space-between">
                {
                    [0,1,2,3].map(k => (
                        k===3 ? (
                            <Skeleton key={k} variant="rectangular" height={292} sx={{borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px', width: '149px'}}/>
                        ) : (
                            <Skeleton key={k} variant="rounded" height={292} sx={{borderRadius: '12px', width: '294px'}}/>
                        )   
                    ))
                }
            </Box>
        ) : (
            <>
            <Box ref={sliderRef} className="keen-slider" mt="20px">
                {
                    progress.map((pl,k) => (
                        <Box key={k} className="keen-slider__slide" p="20px" style={{maxWidth: '20%', minWidth: '20%', background: `linear-gradient(${bg[k]})`, p: '16px', borderRadius: '12px', opacity: (k===0 ? (pl.percentage===100 ? '50%' : '') : pl.percentage===100 ? '50%' : (progress[k-1].percentage===100? '50' : '50%')), color: (k===0 ? (pl.percentage===100 ? '#313131' : '#313131') : pl.percentage===100 ? '' : (progress[k-1].percentage===100? '' : '#fff'))}}>
                            <Box fontSize="14px" fontWeight={600} color={k===0 ? '#313131' : (progress[k-1].percentage===100 ? '#313131' : '')} mb={k===0 ? (pl.percentage === 100 ? '89px': '16px') : pl.percentage === 100 ? '89px' : (progress[k-1].percentage===100 ? '16px': '89px')}>Level {pl.level}</Box>
                            {
                                pl.percentage === 100 ? (
                                    <></>
                                ) : (
                                    (k === 0 ? <Box bgcolor="#fff" color="#F57F17" borderRadius="4px" p="4px" fontSize="12px" component="span">Ongoing</Box> : (progress[k-1].taken === progress[k-1].target) ? <Box bgcolor="#fff" color="#F57F17" borderRadius="4px" p="4px" fontSize="12px" component="span">Ongoing</Box> : '')
                                )
                            }
                            <Box fontSize="12px" color={k===0 ? '#8496BB' : (progress[k-1].percentage===100 ? '#8496BB' : '')} fontWeight={400} mb="10px" mt="48px">Target Questions</Box>
                            <Box fontSize="20px" fontWeight={600} mb="24px">{pl.taken}/{pl.target}</Box>
                            <LinearProgress  variant="determinate" value={pl.percentage} className={classes.root} sx={{position: 'relative', overflow: 'hidden', display: 'block', zIndex: 0, height: '8px', bgcolor: '#fff', mb: '16px'}} />
                            <Box fontSize="12px">Date to accomplish</Box>
                            <Box fontSize="12px">{pl.date_accomplish === null ? '' : moment(pl.date_accomplish).format('MMM. D, YYYY')}</Box>
                        </Box>
                    ))
                }
            </Box>
            <Box className="dots" display="flex" mt="16px" justifyContent="center">
                {
                    [0,1,2].map(k => (
                        <Box key={k} borderRadius="100px" height="8px" width={currentSlide === k ? "32px" : "8px"} mr="8px" bgcolor={currentSlide === k ? "#2466EF" : "#CFDEFC"} sx={{cursor: 'pointer'}} onClick={ () =>{
                            instanceRef.current?.moveToIdx(k)
                        } }></Box>
                    ))
                }
            </Box>
            </>
        )
    )
}