import { rqx } from '../../../../core/request/API';
import { env } from '../../../../core/Env';

export const fetchSubjects = async(setSubjects, __SESSION, setLoading) => {
    let fetch = await rqx.get(`<<tool>>/${env()}/subject/examSubjects`, {"licensure_id": __SESSION.ulicensure[0].id, "aid": parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1])})
    if(fetch.length >= 1){
        setSubjects(fetch);
    }
    setLoading(false);
}

export const fetchTopics = async(stid, aid, setTopics, setLoading) => {
    let fetch = await rqx.get(`<<tool>>/${env()}/examTopic/get`, {subject_type_id: stid, aid: aid})
    if(fetch.length >= 1){
        setTopics(fetch);
    }
    setLoading(false);
}

export const convertTime = (time) => {
    let h = Math.floor(time % (3600*24) / 3600);
    let m = Math.floor(time % 3600 / 60);
    let s = Math.floor(time % 60);
    let hrs = h < 10 ? "0"+ h : h;
    let min = m < 10 ? "0" + m : m;
    let sec = s < 10 ? "0" + s : s;
    return hrs+':'+min+':'+sec;
}

export const fetchActivities = async(overview, urlParam, __SESSION, setResults, setIsDialog, setOverview, setLoading) => {
    let fetch = await rqx.get(`<<tool>>/${env()}/examActivity/read`, {set_id: urlParam.get('setId'), esid: urlParam.get('esid'), take: urlParam.get('take') + 1, aid: parseInt(window.atob(__SESSION.ainfo.aid).split('_')[1])})
    if(fetch.activities.length > 0){
        setResults(fetch)
        if(urlParam.get('from') !== 'show-answer'){
            if(fetch.estatus === 1){
                //setIsDialog({open: true, name: 'success'})
            }else{
                setOverview({...overview, /* open: true, */ loading: true})
                let fetchSED = await rqx.get(`<<tool>>/${env()}/examActivity/SEDResults`, {uaid: fetch.activities[0].id})
                if(fetchSED.length > 0){
                    setOverview({...overview, open: false, loading: false, summary: fetch.activities[0], sed: fetchSED})
                }
            }
        }
        setLoading(false);
    }
}

export const convertTake = (n) => {
    return["st","nd","rd"][((n+90)%100-10)%10-1]||"th";
}