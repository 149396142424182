import { Box, Button, Grid, Skeleton } from '@mui/material';
import { useState } from 'react';
import { CAS } from '../utils/modals/CAS'
export const GrowthRate = ({growthRate}) => {
    const [avg, setAvg] = useState({isDialog: false, tq: 0, total: 0});
    return (
        growthRate.stat === 'loading' ? (
            <Box bgcolor="#fff" borderRadius="12px" mb="20px">
                <Skeleton variant="text" width={180} sx={{ fontSize: '16px', mb: '10px' }} />
                <Grid container spacing={3} sx={{height: '100%'}}>
                {
                    [0,1,2,3,4,5].map(k => (
                        <Grid item xs={2} key={k} sx={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                            <Box display="flex" flexDirection="column" justifyContent="flex-end" height="100%" width={32} borderRadius="20px">
                                <Skeleton variant="rounded" width="100%" height={243} sx={{borderRadius: '6px'}}/>
                            </Box>
                            <Skeleton variant="text" width={40} sx={{ fontSize: '1rem' }} />
                        </Grid>
                    ))
                }
                </Grid>
                <Skeleton variant="rounded" width="100%" height={70} sx={{borderRadius: '12px', mt: '15px'}}/>
            </Box>
        ) : (
            <>
            <Box bgcolor="#fff" borderRadius="12px">
                <Box color="#8496BB">Probability to pass: <Box component="span" color="#008F5D" fontWeight={400}>{Math.round(growthRate.ptp)}%</Box></Box>
                <Box mt="32px" mb="24px">
                    <Box height={300} bgcolor="#fff">
                        <Grid container spacing={3} sx={{height: '100%'}}>
                            {
                                growthRate.gr.length > 0 ? (
                                    growthRate.gr.map((v, k) => (
                                        <Grid item xs={2} key={k} sx={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                            <Box display="flex" flexDirection="column" justifyContent="flex-end" height="100%" bgcolor="#dde9e7" width={32} borderRadius="6px">
                                                <Box height={`${v.grade}%`} bgcolor="#165320" borderRadius="6px" />
                                            </Box>
                                            <Box fontSize={14} mt="12px" fontWeight={500} color="#313131">{v.label}</Box>
                                        </Grid>
                                    ))
                                ) : (
                                    ['AFAR', 'AUD', 'FAR', 'MAS', 'RFBT', 'TAX'].map((v,k) => (
                                        <Grid item xs={2} key={k} sx={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                            <Box display="flex" flexDirection="column" justifyContent="flex-end" height="100%" bgcolor="#dde9e7" width={32} borderRadius="6px">
                                                <Box height={`0%`} bgcolor="#165320" borderRadius="6px" />
                                            </Box>
                                            <Box fontSize={14} mt="12px" fontWeight={500} color="#313131">{v}</Box>
                                        </Grid>
                                    ))
                                )
                            }
                        </Grid>
                    </Box>
                </Box>
                <Box bgcolor="#fff" border="1px solid #dde9e7" borderRadius="12px" p="16px" display="flex" justifyContent="space-between" alignItems="center">
                    <Box color="#7aa78d">Average Speed: <Box component="span" color="#313131" fontWeight={400}>{Number.isInteger(growthRate.avg) ? growthRate.avg : growthRate.avg.toFixed(2)} sec/question</Box></Box>
                    <Button variant="outlined" onClick={()=>setAvg({...avg, isDialog: true})} disabled={growthRate.avg === 0 ? true : false}>Calculate my average speed</Button>
                </Box>
            </Box>
            {
                avg.isDialog &&
                <CAS avgSpeed={growthRate.avg}  avg={avg} setAvg={setAvg}/>
            }
            </>
        )
    )
}