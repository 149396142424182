import { Box, Divider } from '@mui/material';
import { Index as Users } from './users/Index'
import { Index as AQ } from './aq/Index'
import { useContext, useState } from 'react';
import { ToolContext } from '../../../core/context/ToolContext';
export const Reports = () => {
    const { tool_state } = useContext(ToolContext)
    const __SESSION = tool_state.__SESSION
    const [active, setActive] = useState(0)
    return (
        <Box height="100%"  pt="64px" bgcolor="#fff">
            <Box borderRight="1px solid #E5EBF6" p="41px 56px">
                <Box fontWeight={700} fontSize="24px" lineHeight="38px" mb="16px">Reports</Box>
                <Box display="flex" mb="16px">
                    <Box mr="10px" bgcolor={active === 0 ? "#008f5d" : '#f8f9fa'} color={active === 0 ? "#fff" : '#000'} borderRadius="6px" p="6px 30px" fontSize="14px" sx={{cursor: 'pointer'}} onClick={()=>setActive(0)}>Users</Box>
                    <Box bgcolor={active === 1 ? "#008f5d" : '#f8f9fa'} color={active === 1 ? "#fff" : '#000'} borderRadius="6px" p="6px 30px" fontSize="14px" sx={{cursor: 'pointer'}} onClick={()=>setActive(1)}>Answered Questions</Box>
                </Box>
                <Divider sx={{borderColor: '#ccdbe7', mb: '16px'}}/>
                {
                    active === 0 ? (
                        <Users __SESSION={__SESSION}/>
                    ) : (
                        <AQ __SESSION={__SESSION}/>
                    )
                }
            </Box>
        </Box>
    )
}