// Libraries
import React from 'react';
import ReactDOM from 'react-dom';

// Assets
import './assets/css/global.css';

// Layouts
import { Index as IndexPersonal } from './__personal';
import { Index as IndexBusiness } from './__business';
import { Index as IndexMain } from './__main';
import { Index as IndexUBR } from './__ubr';

let Core
const loc = window.location.pathname
const subdir = loc.split('/')[1]

if (loc === '/ubr') {
    Core = IndexUBR
}else if(subdir === 'biz'){
    Core = IndexBusiness
}else if(subdir === 'psl'){
    Core = IndexPersonal
}else{
    Core = IndexMain
}


ReactDOM.render(<Core/>, document.getElementById('root'));