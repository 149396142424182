import { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { VM } from '../VM';
import { Home } from '../../__business/components/home/Index';
import { Reports } from '../../__business/components/reports/Index';
import { TakeExam } from '../../__business/components/takeExam/Index';
import { Leaderboard } from '../../__business/components/leaderboard/Index';
import { Profile } from '../../__business/components/profile/Index';
let view = VM();
const Navigation = lazy(() => import(`../../__business/components/layouts/navigations${view}/Index`))

export const WebRoute = () => {
    const location = useLocation().pathname;
    return (
        <Switch>
            {/* HOME */}
            <Route exact path="/biz" render={props=>(Render(Home, props, location))}/>
            <Route exact path="/biz/reports" render={props=>(Render(Reports, props, location))}/>
            <Route exact path="/biz/take-exam" render={props=>(Render(TakeExam, props, location))}/>
            <Route exact path="/biz/leaderboard" render={props=>(Render(Leaderboard, props, location))}/>
            <Route exact path="/biz/profile" render={props=>(Render(Profile, props, location))}/>

            {/* 404 NOT FOUND */}
            <Route>
                <Container>
                    <Box display="flex">
                        Error 404
                    </Box>
                </Container>
            </Route>
        </Switch>
    )
}

const Render = (Component, props, location) => (
    <Box width="100%" height="100%">
        <Box display="flex" width="100%" height="100%">
            <Navigation side={0} />
            <Box display="flex" width="100%" height="100%">
                <Navigation side={1} />
                <Box width="100%" height="100%">
                    <Component {...props}/>
                </Box>
            </Box>
        </Box>
    </Box>
)